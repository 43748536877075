export const updateIndex = (payload) => {
  return {
    type: 'UPDATE_INDEX',
    payload: payload
  };
};

export const closeWindow = (payload) => {
  return {
    type: 'CLOSE_WINDOW',
    payload: payload
  };
};


export const showPhotographiesWindow = (payload) => {
  return {
    type: 'SHOW_PHOTOGRAPHIES_WINDOW',
    payload: payload
  };
 };


export const showVideosWindow = (payload) => {
  return {
    type: 'SHOW_VIDEOS_WINDOW',
    payload: payload
  };
 };

 export const showGraphics = (payload) => {
  return {
    type: 'SHOW_GRAPHICS_WINDOW',
    payload: payload
  };
 };

 export const showAnimations = (payload) => {
  return {
    type: 'SHOW_ANIMATIONS_WINDOW',
    payload: payload
  };
 };