const GraphicsData = {
  brandarmies: {
    data: [
      {
        url: 'logo.png',
        speed: '0.6',
        offset: '0.4',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center'
      },
      {
        url: 'station.png',
        speed: '0.3',
        offset: '0.9',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-left'
      },
      {
        url: 'cards.png',
        speed: '.5',
        offset: '1.4',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center'
      },
      {
        url: 'web.png',
        speed: '.4',
        offset: '2',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-left'
      },
      {
        url: 'iphone.png',
        speed: '.5',
        offset: '2.6',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-right'
      },
      {
        url: 'characters2.jpg',
        speed: '.2',
        offset: '3',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center'
      },
      {
        url: 'king_development.png',
        speed: '.4',
        offset: '3.7',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-left'
      },
      {
        url: 'knight_development.png',
        speed: '.2',
        offset: '4.1',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-right'
      },
      {
        url: 'landing.png',
        speed: '.1',
        offset: '4.9',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center'
      }
    ],
    desc: {
      title: 'Brandarmies',
      text:
        "Logo creation, Brand Identity, Character Design, Design. <br>In collaboration with <a href='https://www.instagram.com/_the_ramen_noodle_/' target='_blank'>the ramen noodle</a>."
    },
    links: []
  },
  kolibri: {
    data: [
      {
        url: 'app.png',
        speed: '0.6',
        offset: '0.7',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center-small'
      },
      {
        url: 'birthday.png',
        speed: '0.3',
        offset: '1',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-left'
      },
      {
        url: 'holiday.png',
        speed: '.5',
        offset: '1.8',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center'
      },
      {
        url: 'phone.png',
        speed: '.4',
        offset: '2',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-left'
      },
      {
        url: 'paper.png',
        speed: '.5',
        offset: '2.6',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center-small'
      },
      {
        url: 'ift3.png',
        speed: '.2',
        offset: '3',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-right-bigger'
      },
      {
        url: 'imt1.png',
        speed: '0.3',
        offset: '3.8',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-left'
      },
      {
        url: 'imt2.png',
        speed: '.5',
        offset: '4.2',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center'
      },
      {
        url: 'imt3.png',
        speed: '.4',
        offset: '4.8',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-left'
      },
      {
        url: 'ift1.png',
        speed: '.5',
        offset: '5',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-right'
      },
      {
        url: 'ift2.png',
        speed: '.2',
        offset: '5.7',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-left'
      }
    ],
    desc: {
      title: 'Kolibri Games',
      text:
        'A collection of my favourite graphic design projects while working at Kolibri Games. Created a variety of content, using and adjusting already existing assets. Kolibri Games is a mobile games studio based in Berlin.'
    },
    links: [
      {
        type: 'website',
        url: 'https://www.kolibrigames.com/'
      }
    ]
  },
  laharCannibal: {
    data: [
      {
        url: 'redm.png',
        speed: '0.9',
        offset: '0.7',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center'
      },
      {
        url: 'whitem.png',
        speed: '0.3',
        offset: '1',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-right-bigger'
      },
      {
        url: 'cannibal.gif',
        speed: '0.1',
        offset: '1.9',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center'
      }
    ],
    desc: {
      title: 'Lahar Berlin Cannibal',
      text:
        '"My thoughts are eating me alive", an illustration created for Lahar Berlin issue #4 Cannibal. Lahar Berlin is a thematic posterzine made of short texts, poetry, illustrations, photos and experiments. Each pocket-sized issue opens up into a magnificent original poster.'
    },
    links: [
      {
        type: 'tumblr',
        url: 'https://laharberlin.tumblr.com/'
      }
    ]
  },
  laharLimbo: {
    data: [
      {
        url: 'limbo.gif',
        speed: '0.3',
        offset: '0.8',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center'
      }
    ],
    desc: {
      title: 'Lahar Berlin #5 - Limbo is out now!',
      text:
        'I had fun while art directing and designing Lahar’s issue #5 - Limbo. Lahar Berlin is a thematic zine made of short texts, poetry, illustrations, photos and experiments. Each pocket-sized issue opens up into a magnificent original poster. You can find more about Lahar Berlin and issue #5 if you click below.'
    },
    links: [
      {
        type: 'tumblr',
        url: 'https://laharberlin.tumblr.com/'
      }
    ]
  },
  collage: {
    data: [
      {
        url: 'dad.gif',
        speed: '0.9',
        offset: '0.6',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center-small'
      },
      {
        url: 'pataworld.png',
        speed: '0.3',
        offset: '1',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center-bigger'
      }
    ],
    desc: {
      title: 'Experiments',
      text: 'Playing around in order to create a new narrative.'
    },
    links: []
  },
  website: {
    data: [
      {
        url: 'screen3.gif',
        speed: '0.9',
        offset: '0.4',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center'
      },
      {
        url: 'screen2.gif',
        speed: '0.3',
        offset: '1',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center-bigger'
      },
      {
        url: 'screen.gif',
        speed: '0.7',
        offset: '1.9',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-left-bigger'
      },
      {
        url: 'screen1.gif',
        speed: '0.5',
        offset: '2.3',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-right-bigger'
      },
      {
        url: 'screen4.gif',
        speed: '0.7',
        offset: '3',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center-bigger'
      }
    ],
    desc: {
      title: 'Website illustrations and design',
      text:
        "Designed and illustrated this whole thing. <br>Developed by <a href='https://www.instagram.com/_the_ramen_noodle_/' target='_blank'>the ramen noodle</a>."
    },
    links: []
  }
};

export default GraphicsData;
