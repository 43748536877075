import React from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { closeWindow, updateIndex } from '../actions';
import lines from '../images/desktop/windows/lines.png';
import minim from '../images/desktop/windows/minim.png';
import close from '../images/desktop/windows/x.png';
import { getWindowsProps } from '../utils/helpers';

class NotebookPage extends React.Component {
  render() {
    const notebookData = getWindowsProps(this.props.windows.windowsData, 'Notebook');

    const windowStyle = {
      position: 'absolute',
      top: notebookData.position.top,
      left: notebookData.position.left,
      zIndex: notebookData.z,
    };

    const linesBg = {
      backgroundColor: '#F0EDED',
      backgroundImage: `url(${lines})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '90% 45%',
    };

    const windowsWidth = {
      width: notebookData.width,
    };

    // let cvUrl = require(`../images/Eleni_Gkelameri_CV_2022.pdf`);
    let avatarUrl = require(`../images/avatar.mov`);

    let controls = {
      playing: true,
      height: '100%',
      width: '100%',
      showControls: false,
      url: avatarUrl,
      playsInline: true,
      loop: true,
    };

    return (
      <Draggable bounds='body' onMouseDown={() => this.props.updateIndex(notebookData)}>
        <div style={windowStyle} className={notebookData.show ? '' : 'hidden'}>
          <div className='father work600' style={windowsWidth}>
            <div style={linesBg} className='topbar'>
              <div className='child close'>
                <img
                  src={close}
                  onClick={() =>
                    this.props.closeWindow({
                      ...notebookData,
                      show: false,
                    })
                  }
                  alt=''
                />
              </div>
              <div className='child title'>
                <span>My Notebook</span>
              </div>
              <div className='child maximise'>
                <img src={minim} alt='' />
              </div>
            </div>
            {/* <div className="bar">
                            <div className="child items"><p>5 items</p></div>
                            <div className="child disk"><p>3 GB in disk</p></div>
                            <div className="child available"><p>11 MB available</p></div>
                        </div> */}
            <div className='notebook-container work400'>
              <div>
                <div className='avatar'>
                  <video src={controls.url} autoPlay muted loop playsInline></video>
                </div>
                <p className='work600'>about</p>
                <br></br>
                <p>
                  Eleni Gkelameri is a Greek multimedia artist who is now based in Berlin.
                  She is passionate about visual storytelling and uses moving images and
                  cross-disciplinary techniques, to create narratives that do not adhere
                  to traditional structures. She embraces a versatile approach and thrives
                  in a creative flow guided by curiosity, trial, and error. Since 2017,
                  Eleni has been an active member of collaborative teams, both as an
                  in-house artist and independently. She developed professional skills by
                  editing, animating, and designing branded content for well-known brands
                  and through her own projects. She is a graduate of Universität der
                  Künste Berlin, and her work has been exhibited in art galleries and film
                  festivals across Germany and Greece. <br></br>
                  <br></br>Apart from that, Eleni enjoys taking her dog, Matilda, for long
                  walks and playing Stardew Valley.
                </p>
                <br></br>
                <p className='work600'>areas of expertise</p>
                <br></br>
                <p>Video Editing</p>
                <p>Motion Graphics</p>
                <p>Mixed-Media Animation</p>
                <p>Art Direction</p>
                <p>Composition</p>
                <p>Visual Development</p>
                <br></br>
                <br></br>
                <p className='work600'>selected festivals & exhibitions</p>
                <br></br>
                <p>
                  <span className='work600'>2022 GRRL HAUS CINEMA</span>
                </p>
                <p>Limbo Fest, Local Films, Berlin, DE</p>
                <br></br>
                <p>
                  <span className='work600'>
                    2022 TAF Thessaloniki Animation Festival
                  </span>
                </p>
                <p>Official Selection, Greek Panorama, GR</p> <br></br>
                <p>
                  <span className='work600'>2022 RFF Rewind And Fast Forward</span>
                </p>
                <p>Soma Art Space, Group Show, Berlin, DE</p>
                <br></br>
                <p>
                  <span className='work600'>2019 Zebra Poetry Film Festival</span>
                </p>
                <p>New Talents, Kino in der Kulturbrauerei, Berlin, DE</p>
                <br></br>
                <p>
                  <span className='work600'>2015 ADAF Athens Digital Art Festival</span>
                </p>
                <p>Public Spaces, Athens, GR</p>
                
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { updateIndex, closeWindow })(NotebookPage);
