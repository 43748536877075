import {
  faFacebook,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

class Links extends React.Component {
  render() {
    let links = this.props.links;
    const RenderedLinks = links.map(link => {
      if (link.type === 'website') {
        return (
          <div key={link.type}>
            <a href={link.url} target="_blank" el="noopener noreferrer">
              <FontAwesomeIcon icon={faDesktop} />
            </a>
          </div>
        );
      } else if (link.type === 'facebook') {
        return (
          <div key={link.type}>
            <a href={link.url} target="_blank" el="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          </div>
        );
      } else if (link.type === 'youtube') {
        return (
          <div key={link.type}>
            <a href={link.url} target="_blank" el="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
        );
       }
      // else if (link.type === 'tumblr') {
      //   return (
      //     <div key={link.type}>
      //       <a href={link.url} target="_blank">
      //         <FontAwesomeIcon icon={faTumblr} />
      //       </a>
      //     </div>
      //   );
      // }
    });

    return <div className="container">{RenderedLinks}</div>;
  }
}
export default Links;
