import React from "react";
import Draggable from "react-draggable";
import { connect } from "react-redux";
import { closeWindow, updateIndex } from "../actions";
import lines from "../images/desktop/windows/lines.png";
import minim from "../images/desktop/windows/minim.png";
import close from "../images/desktop/windows/x.png";
import { getWindowsProps } from "../utils/helpers";

class ErrorWindow extends React.Component {
  bounceEverything = title => {
    let bd = document.getElementsByTagName("body");
    bd[0].classList.add("no-events");

    const elems = document.getElementsByClassName(title);

    for (let i = 0; i < elems.length; i++) {
      let delay = i * 111;
      setTimeout(() => {
        elems[i].classList.add("bncabt");
      }, delay);
    }

    setTimeout(() => {
      const el = document.getElementsByClassName("black-overlay")[0];
      el.classList.remove("hidden");
    }, 2000);

    setTimeout(() => {
      window.location.reload();
    }, 4000);
  };

  render() {
    const errorData = getWindowsProps(this.props.windows.windowsData, "sad memes");

    const windowStyle = {
      position: "absolute",
      top: errorData.position.top,
      left: errorData.position.left,
      zIndex: errorData.z
    };

    const linesBg = {
      backgroundColor: "#F0EDED",
      backgroundImage: `url(${lines})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "85% 45%"
    };

    const windowsWidth = {
      width: errorData.width
    };

    if (errorData.show) {
      this.bounceEverything("desktop-icon");
      this.bounceEverything("react-draggable");
    }

    return (
      <Draggable
        bounds="body"
        onMouseDown={() => this.props.updateIndex(errorData)}
      >
        <div
          style={windowStyle}
          className={errorData.show ? "errror" : "errror hidden"}
        >
          <div className="work600 mother" style={windowsWidth}>
            <div style={linesBg} className="topbar">
              <div className="child close">
                <img src={close} alt="" />
              </div>
              <div className="child title">
                <span>System Crash</span>
              </div>
              <div className="child maximise">
                <img src={minim} alt="" />
              </div>
            </div>
            <div className="error-container work400">
              <div className="message">
                <p>
                  A system crash has occured. The computer will restart now.
                </p>
              </div>
              <div className="button">
                <button className="chicago">OK</button>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  { updateIndex, closeWindow }
)(ErrorWindow);
