import React from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { updateIndex } from '../actions';
import french from '../images/audio/french.wav';
import greek from '../images/audio/metro.mp3';
import popo from '../images/audio/popo.mp3';
import blueFolder from '../images/desktop/icons/blue-folder.png';
import button from '../images/desktop/icons/button.png';
import redFolder from '../images/desktop/icons/red-folder.png';
import bin from '../images/desktop/icons/trash.png';
import AnimationsPage from './AnimationsPage';
import AnimationsWindow from './AnimationsWindow';
import CollageWindow from './CollageWindow';
import DocumentsWindow from './DocumentsWindow';
import ElExp from './ElExp';
import EmmaBuild from './EmmaBuild';
import ErrorWindow from './ErrorWindow';
import FlowerExp from './FlowerExp';
import GraphicsPage from './GraphicsPage';
import GraphicsWindow from './GraphicsWindow';
import HippoExp from './HippoExp';
import HippoExp2 from './HippoExp2';
import Kota from './Kota';
import LaharExp from './LaharExp';
import LegalPage from './LegalPage';
import LegzExp from './LegzExp';
import ManExp from './ManExp';
import NotebookPage from './NotebookPage';
import PhotographiesPage from './PhotographiesPage';
import PhotographiesWindow from './PhotographiesWindow';
import PostitWindow from './PostitWindow';
import PostitWindowTwo from './PostitWindowTwo';
import RedButtonWindow from './RedButtonWindow';
import ThcExp from './ThcExp';
import TodoPage from './TodoPage';
import TrGif from './TrGif';
import TrashWindow from './TrashWindow';
import Ugly from './Ugly';
import VideosPage from './VideosPage';
import VideosWindow from './VideosWindow';
class FolderIcon extends React.Component {
  constructor(props) {
    super(props);
    this.audioFinished = this.audioFinished.bind(this);
  }

  audioFinished() {
    this.counter++;
    this.playing = false;
  }

  playAudio = () => {
    let audio = document.getElementById('audio');
    let audioFiles = [{ src: french }, { src: greek }, { src: popo }];

    if (this.playing) {
      audio.pause();
      audio.currentTime = 0;
      this.playing = false;
      if (this.counter === 2) this.counter = 0;
      else this.counter++;
    } else {
      audio.src = audioFiles[this.counter].src;
      this.playing = true;
      audio.volume = 0.3;
      audio.play();
    }
  };

  clickButton = () => {
    const div = document.getElementsByClassName('redButtonImage')[0];
    const img = require(`../images/desktop/icons/buttonClicked.png`);

    div.src = img;
  };

  toggleFolderStatus(e) {
    const div = e.target.classList;
    if (div.contains('active')) div.remove('active');
    else div.add('active');
  }

  unClickButton = () => {
    const div = document.getElementsByClassName('redButtonImage')[0];
    const img = require(`../images/desktop/icons/button.png`);

    div.src = img;
  };

  render() {
    const data = this.props.windows.iconsData;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const renderedFolders = data.map((folder) => {
      this.playing = false;
      this.counter = 0;
      const styleFolder = {
        position: 'absolute',
        top: folder.top,
        left: '50px',
      };

      const radioIcon = {
        position: 'absolute',
        bottom: folder.bottom,
        left: folder.left,
      };

      const binIcon = {
        position: 'absolute',
        bottom: folder.bottom,
        right: folder.right,
      };

      const styleImage = {
        width: '100px',
        height: '45px',
      };

      if (folder.icon === 'red-folder') {
        return (
          <Draggable bounds='body' key={folder.title}>
            <div
              className='handle desktop-icon'
              data-title={folder.title}
              style={styleFolder}
              onClick={() => this.props.updateIndex(folder)}
              // onClick={this.toggleFolderStatus}
            >
              <div className={folder.icon} style={styleImage}>
                <img src={redFolder} alt='' />
              </div>
              <div className='title work400'>{folder.title}</div>
            </div>
          </Draggable>
        );
      } else if (folder.icon === 'blue-folder') {
        return (
          <Draggable bounds='body' key={folder.title}>
            <div
              className='handle desktop-icon'
              data-title={folder.title}
              style={styleFolder}
              onClick={() => this.props.updateIndex(folder)}
            >
              <div className={folder.icon} style={styleImage}>
                <img src={blueFolder} alt='' />
              </div>
              <div className='title work400'>{folder.title}</div>
            </div>
          </Draggable>
        );
      } else if (folder.icon === 'dog-selfies') {
        return (
          <Draggable bounds='body' key={folder.title}>
            <div
              className='handle desktop-icon'
              data-title={folder.title}
              style={styleFolder}
              onClick={() => this.props.updateIndex(folder)}
            >
              <div className={folder.icon} style={styleImage}>
                <img src={blueFolder} alt='' />
              </div>
              <div className='title work400'>{folder.title}</div>
            </div>
          </Draggable>
        );
      } else if (folder.icon === 'radio') {
        return (
          <Draggable bounds='body' key={folder.title}>
            <div
              data-tip={folder.tooltip}
              className='handle desktop-icon'
              data-title={folder.title}
              style={radioIcon}
            >
              <iframe
                src='https://open.spotify.com/embed/artist/3NqV2DJoAWsjl787bWaHW7?utm_source=generator'
                width='100%'
                height='152'
                frameBorder='0'
                allowfullscreen=''
                allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
                loading='lazy'
                className='spotify-iframe'
              ></iframe>
              {/* <div className={folder.icon}>
                                <img src={radio} alt='' />
                                <audio
                                    id='audio'
                                    volume='0.1'
                                    onEnded={this.audioFinished}></audio>
                            </div> */}
            </div>
          </Draggable>
        );
      } else if (folder.icon === 'red-button') {
        return (
          <Draggable bounds='body' key={folder.title}>
            <div
              data-tip={folder.tooltip}
              className='handle desktop-icon'
              data-title={folder.title}
              style={radioIcon}
              onClick={() => this.props.updateIndex(folder)}
            >
              <div
                className={folder.icon}
                onMouseDown={this.clickButton}
                onMouseUp={this.unClickButton}
              >
                <img className='redButtonImage' src={button} alt='' />
              </div>
            </div>
          </Draggable>
        );
      } else if (folder.icon === 'bin') {
        return (
          <Draggable bounds='body' key={folder.title}>
            <div
              data-tip={folder.tooltip}
              className='handle desktop-icon'
              data-title={folder.title}
              style={binIcon}
              onClick={() => this.props.updateIndex(folder)}
            >
              <div className={folder.icon}>
                <img src={bin} alt='' />
              </div>
            </div>
          </Draggable>
        );
      }
    });

    return (
      <div className='desktop'>
        {renderedFolders}
        {/* <ProjectsWindow></ProjectsWindow> */}
        <DocumentsWindow></DocumentsWindow>
        <VideosWindow></VideosWindow>
        <PhotographiesWindow></PhotographiesWindow>
        <NotebookPage></NotebookPage>
        <TodoPage></TodoPage>
        <GraphicsWindow></GraphicsWindow>
        <TrashWindow></TrashWindow>
        <AnimationsWindow></AnimationsWindow>
        <RedButtonWindow></RedButtonWindow>
        <CollageWindow></CollageWindow>
        <PostitWindow></PostitWindow>
        <PostitWindowTwo></PostitWindowTwo>
        <PhotographiesPage></PhotographiesPage>
        <VideosPage></VideosPage>
        <GraphicsPage></GraphicsPage>
        <AnimationsPage></AnimationsPage>
        <ErrorWindow></ErrorWindow>
        <LegalPage></LegalPage>
        <ManExp></ManExp>
        <ThcExp></ThcExp>
        <ElExp></ElExp>
        <EmmaBuild></EmmaBuild>
        <FlowerExp></FlowerExp>
        <HippoExp></HippoExp>
        <HippoExp2></HippoExp2>
        <LaharExp></LaharExp>
        <LegzExp></LegzExp>
        {/* <Showreel></Showreel> */}
        <TrGif></TrGif>
        <Kota></Kota>
        <Ugly></Ugly>
        <ReactTooltip
          globalEventOff={isMobile ? 'click' : undefined}
          effect='solid'
          className='tooltip work600'
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { updateIndex })(FolderIcon);
