import React from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { closeWindow, showVideosWindow, updateIndex } from '../actions';
import floppy from '../images/desktop/items/floppy.png';
import lines from '../images/desktop/windows/lines.png';
import minim from '../images/desktop/windows/minim.png';
import close from '../images/desktop/windows/x.png';
import { getWindowsProps } from '../utils/helpers';

class VideosWindow extends React.Component {
  render() {
    const videosData = getWindowsProps(
      this.props.windows.windowsData,
      'Videos'
    );

    const filesInfo = [
      // {
      //   title: 'showreel',
      //   content: 'showreel'
      // },
      {
        title: 'hippocampus',
        content: 'hippocampus'
      },
      
    ];

    const windowStyle = {
      position: 'absolute',
      top: videosData.position.top,
      left: videosData.position.left,
      zIndex: videosData.z
    };

    const linesBg = {
      backgroundColor: '#F0EDED',
      backgroundImage: `url(${lines})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '85% 45%'
    };

    const renderedFiles = filesInfo.map(file => {
      return (
        <div
          className="files"
          key={file.title}
          onClick={() => this.props.showVideosWindow(file.content)}
        >
          <div>
            <img src={floppy} alt="" />
          </div>
          <div>
            {' '}
            <span>{file.title}</span>
          </div>
        </div>
      );
    });

    const windowsWidth = {
      width: videosData.width
    };

    return (
      <Draggable
        bounds="body"
        onMouseDown={() => this.props.updateIndex(videosData)}
      >
        <div style={windowStyle} className={videosData.show ? '' : 'hidden'}>
          <div className="mother work600" style={windowsWidth}>
            <div style={linesBg} className="topbar">
              <div className="child close">
                <img
                  src={close}
                  onClick={() =>
                    this.props.closeWindow({ ...videosData, show: false })
                  }
                  alt=""
                />
              </div>
              <div className="child title">
                <span>Video Work</span>
              </div>
              <div className="child maximise">
                <img src={minim} alt="" />
              </div>
            </div>
            <div className="bar">
              <div className="child items">
                <p>4 items</p>
              </div>
              <div className="child disk">
                <p>3 GB in disk</p>
              </div>
              <div className="child available">
                <p>11 MB available</p>
              </div>
            </div>
            <div className="doc-container work400">
              <div className="list">{renderedFiles}</div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
  updateIndex,
  closeWindow,
  showVideosWindow
})(VideosWindow);
