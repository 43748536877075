import React from "react";
import Draggable from "react-draggable";
import floppy from "../images/desktop/items/photo.png";
import lines from "../images/desktop/windows/lines.png";
import close from "../images/desktop/windows/x.png";
import minim from "../images/desktop/windows/minim.png";
import { connect } from "react-redux";
import { getWindowsProps } from "../utils/helpers";
import { updateIndex, closeWindow, showPhotographiesWindow } from "../actions";

class PhotographiesWindow extends React.Component {
  render() {
    const photographiesData = getWindowsProps(
      this.props.windows.windowsData,
      "Photography"
    );

    const filesInfo = [
      {
        title: "places",
        content: "places"
      },
      {
        title: "faces",
        content: "faces"
      },
      {
        title: "moments",
        content: "moments"
      },
      {
        title: "cyanotypes",
        content: "cyanotypes"
      },
      {
        title: "daguerreotypes",
        content: "daguerreotypes"
      }
    ];

    const windowStyle = {
      position: "absolute",
      top: photographiesData.position.top,
      left: photographiesData.position.left,
      zIndex: photographiesData.z
    };

    const linesBg = {
      backgroundColor: "#F0EDED",
      backgroundImage: `url(${lines})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "85% 45%"
    };

    const renderedFiles = filesInfo.map(file => {
      return (
        <div
          className="files"
          key={file.title}
          onClick={() => this.props.showPhotographiesWindow(file.title)}
        >
          <img src={floppy} alt="" />
          <span>{file.title}</span>
        </div>
      );
    });

    const windowsWidth = {
      width: photographiesData.width
    };

    return (
      <Draggable
        bounds="body"
        onMouseDown={() => this.props.updateIndex(photographiesData)}
      >
        <div
          style={windowStyle}
          className={photographiesData.show ? "" : "hidden"}
        >
          <div className="mother work600" style={windowsWidth}>
            <div style={linesBg} className="topbar">
              <div className="child close">
                <img
                  src={close}
                  onClick={() =>
                    this.props.closeWindow({
                      ...photographiesData,
                      show: false
                    })
                  }
                  alt=""
                />
              </div>
              <div className="child title">
                <span>Photography</span>
              </div>
              <div className="child maximise">
                <img src={minim} alt="" />
              </div>
            </div>
            <div className="bar">
              <div className="child items">
                <p>5 items</p>
              </div>
              <div className="child disk">
                <p>3 GB in disk</p>
              </div>
              <div className="child available">
                <p>11 MB available</p>
              </div>
            </div>
            <div className="doc-container work400">
              <div className="list">{renderedFiles}</div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  { updateIndex, closeWindow, showPhotographiesWindow }
)(PhotographiesWindow);
