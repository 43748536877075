import React from 'react';
import Draggable from 'react-draggable';
import lines from '../images/desktop/windows/lines.png';
import close from '../images/desktop/windows/x.png';
import minim from '../images/desktop/windows/minim.png';
import { connect } from 'react-redux';
import { getWindowsProps } from '../utils/helpers';
import { updateIndex, closeWindow } from '../actions'
import floppy from '../images/desktop/items/floppy.png';

class TrashWindow extends React.Component {
    render () {
        const trashData = getWindowsProps(this.props.windows.windowsData, 'Bin');

        const windowStyle = {
            position: 'absolute',
            top: trashData.position.top,
            left: trashData.position.left,
            zIndex: trashData.z
        }

        const linesBg = {
            backgroundColor:'#F0EDED',
            backgroundImage: `url(${lines})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '90% 45%'
        }

        const windowsWidth = {
            width: trashData.width
        }

        return (
            <Draggable bounds="body" onMouseDown={() => this.props.updateIndex(trashData)}>
                <div style={windowStyle} className={trashData.show ? '' : 'hidden'}>
                    <div className="father work600" style={windowsWidth}>
                        <div style={linesBg} className='topbar'>
                            <div className="child close">
                                <img src={close} onClick={() => this.props.closeWindow({...trashData, show: false})} alt=""/>
                            </div>
                            <div className="child title"><span>Trash</span></div>
                            <div className="child maximise">
                                <img src={minim} alt=""/>
                            </div>
                        </div>
                        <div className="trash-container work400">
                            <div className="list">
                                <div className="files">
                                    <img src={floppy} alt=""/>
                                    <span>final.psd</span>
                                </div>
                                <div className="files">
                                    <img src={floppy} alt=""/>
                                    <span>finalfinal.psd</span>
                                </div>
                                <div className="files">
                                    <img src={floppy} alt=""/>
                                    <span>final3.psd</span>
                                </div>
                                <div className="files">
                                    <img src={floppy} alt=""/>
                                    <span>finalfasfsaf.psd</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Draggable>
            )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps, { updateIndex, closeWindow })(TrashWindow);
