import React from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { closeWindow, updateIndex } from '../actions';
import notebook from '../images/desktop/documents/notebook.png';
import todo from '../images/desktop/documents/todo.png';
import lines from '../images/desktop/windows/lines.png';
import minim from '../images/desktop/windows/minim.png';
import close from '../images/desktop/windows/x.png';
import { getWindowsProps } from '../utils/helpers';

class DocumentsWindow extends React.Component {
  // openCV = () => {
  //   let cvUrl = require(`../images/Eleni_Gkelameri_CV_2022.pdf`);
  //   window.open(cvUrl, '_blank');
  // };

  render() {
    const documentsData = getWindowsProps(
      this.props.windows.windowsData,
      'Documents'
    );
    const notebookData = getWindowsProps(
      this.props.windows.windowsData,
      'Notebook'
    );
    const todoData = getWindowsProps(this.props.windows.windowsData, 'Todo');

    const windowStyle = {
      position: 'absolute',
      top: documentsData.position.top,
      left: documentsData.position.left,
      zIndex: documentsData.z
    };

    const linesBg = {
      backgroundColor: '#F0EDED',
      backgroundImage: `url(${lines})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '85% 45%'
    };

    const windowsWidth = {
      width: documentsData.width
    };

    return (
      <Draggable
        bounds="body"
        onMouseDown={() => this.props.updateIndex(documentsData)}
      >
        <div style={windowStyle} className={documentsData.show ? '' : 'hidden'}>
          <div className="mother work600" style={windowsWidth}>
            <div style={linesBg} className="topbar">
              <div className="child close">
                <img
                  src={close}
                  onClick={() =>
                    this.props.closeWindow({ ...documentsData, show: false })
                  }
                  alt=""
                />
              </div>
              <div className="child title">
                <span>{documentsData.title}</span>
              </div>
              <div className="child maximise">
                <img src={minim} alt="" />
              </div>
            </div>
            <div className="bar">
              <div className="child items">
                <p>2 items</p>
              </div>
              <div className="child disk">
                <p>9.9 GB in disk</p>
              </div>
              <div className="child available">
                <p>11 MB available</p>
              </div>
            </div>
            <div className="container work400">
              <div className="documents">
                <div></div>
                <div className="notebook" data-tip="My Notebook">
                  <img
                    src={notebook}
                    onClick={() => this.props.updateIndex(notebookData)}
                    alt=""
                  />
                </div>
                {/* <div className="cv" data-tip="CV">
                  <img src={cv} alt="" onClick={this.openCV} />
                </div> */}
                <div className="todo" data-tip="ToDo List">
                  <img
                    src={todo}
                    onClick={() => this.props.updateIndex(todoData)}
                    alt=""
                  />
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { updateIndex, closeWindow })(
  DocumentsWindow
);
