const imagesData = {
  places: [
    {
      url: "icecream.jpg",
      speed: "0.1",
      offset: "0",
      containerClass: "parallax-cont",
      imageClass: "parallax-right"
    },
    {
      url: "cafecinema.jpg",
      speed: "0.8",
      offset: "0.7",
      containerClass: "parallax-cont",
      imageClass: "parallax-left"
    },
    {
      url: "playground.jpg",
      speed: ".5",
      offset: "1",
      containerClass: "parallax-cont",
      imageClass: "parallax-center"
    },
    {
      url: "bipolar.jpg",
      speed: ".4",
      offset: "1.9",
      containerClass: "parallax-cont",
      imageClass: "parallax-left"
    },
    {
      url: "lake.jpg",
      speed: ".5",
      offset: "2.2",
      containerClass: "parallax-cont",
      imageClass: "parallax-right"
    },
    {
      url: "triangles.jpg",
      speed: ".2",
      offset: "2.9",
      containerClass: "parallax-cont",
      imageClass: "parallax-center"
    },
    {
      url: "pattern.jpg",
      speed: ".7",
      offset: "3.9",
      containerClass: "parallax-cont",
      imageClass: "parallax-left-bigger"
    },
    {
      url: "corner.jpg",
      speed: "1",
      offset: "4.2",
      containerClass: "parallax-cont",
      imageClass: "parallax-right-bigger"
    },
    {
      url: "aplace.jpg",
      speed: ".1",
      offset: "4.9",
      containerClass: "parallax-cont",
      imageClass: "parallax-left-bigger"
    }
  ],
  faces: [
    {
      url: "conversation.jpg",
      speed: "0.1",
      offset: "0",
      containerClass: "parallax-cont",
      imageClass: "parallax-right"
    },
    {
      url: "smile.jpg",
      speed: "0.8",
      offset: "0.7",
      containerClass: "parallax-cont",
      imageClass: "parallax-left"
    },
    {
      url: "blahall.jpg",
      speed: ".5",
      offset: "1",
      containerClass: "parallax-cont",
      imageClass: "parallax-center"
    },
    {
      url: "reflection.jpg",
      speed: ".4",
      offset: "1.9",
      containerClass: "parallax-cont",
      imageClass: "parallax-left"
    },
    {
      url: "bear.jpg",
      speed: ".5",
      offset: "2.2",
      containerClass: "parallax-cont",
      imageClass: "parallax-right"
    },
    {
      url: "dream.jpg",
      speed: ".2",
      offset: "2.9",
      containerClass: "parallax-cont",
      imageClass: "parallax-center-small"
    },
    {
      url: "disorders.jpg",
      speed: ".7",
      offset: "3.9",
      containerClass: "parallax-cont",
      imageClass: "parallax-center"
    },
    {
      url: "self.jpg",
      speed: ".6",
      offset: "4.2",
      containerClass: "parallax-cont",
      imageClass: "parallax-right-bigger"
    },
    {
      url: "celeste.jpg",
      speed: ".4",
      offset: "5",
      containerClass: "parallax-cont",
      imageClass: "parallax-left-smaller"
    },
    {
      url: "vas.jpg",
      speed: ".2",
      offset: "6.2",
      containerClass: "parallax-cont",
      imageClass: "parallax-right-bigger"
    },
    {
      url: "natassa.jpg",
      speed: ".5",
      offset: "6.9",
      containerClass: "parallax-cont",
      imageClass: "parallax-left-bigger"
    },
    {
      url: "faces.jpg",
      speed: ".1",
      offset: "7.5",
      containerClass: "parallax-cont",
      imageClass: "parallax-center"
    }
  ],
  moments: [
    {
      url: "plane.jpg",
      speed: "0.1",
      offset: "0",
      containerClass: "parallax-cont",
      imageClass: "parallax-right"
    },
    {
      url: "hof.jpg",
      speed: "0.8",
      offset: "0.7",
      containerClass: "parallax-cont",
      imageClass: "parallax-left"
    },
    {
      url: "window.jpg",
      speed: ".5",
      offset: "1.3",
      containerClass: "parallax-cont",
      imageClass: "parallax-center-smaller"
    },
    {
      url: "legs.jpg",
      speed: ".1",
      offset: "2",
      containerClass: "parallax-cont",
      imageClass: "parallax-left"
    },
    {
      url: "train.jpg",
      speed: ".5",
      offset: "2.4",
      containerClass: "parallax-cont",
      imageClass: "parallax-right"
    },
    {
      url: "apple.jpg",
      speed: ".2",
      offset: "3.3",
      containerClass: "parallax-cont",
      imageClass: "parallax-center-smaller"
    }
  ],
  cyanotypes: [
    {
      url: "01.jpg",
      speed: "0.3",
      offset: "0",
      containerClass: "parallax-cont",
      imageClass: "parallax-right"
    },
    {
      url: "02.jpg",
      speed: "0.8",
      offset: "0.9",
      containerClass: "parallax-cont",
      imageClass: "parallax-left"
    },
    {
      url: "03.png",
      speed: ".5",
      offset: "1",
      containerClass: "parallax-cont",
      imageClass: "parallax-right"
    }
  ],
  daguerreotypes: [
    {
      url: "01.png",
      speed: "0.7",
      offset: ".3",
      containerClass: "parallax-cont",
      imageClass: "parallax-right"
    },
    {
      url: "02.png",
      speed: "0.5",
      offset: "1",
      containerClass: "parallax-cont",
      imageClass: "parallax-left"
    },
    {
      url: "03.png",
      speed: ".8",
      offset: "1.1",
      containerClass: "parallax-cont",
      imageClass: "parallax-right"
    }
  ]
};

export default imagesData;
