import React from "react";
import Draggable from "react-draggable";
import { connect } from "react-redux";
import { getWindowsProps } from "../utils/helpers";
import { updateIndex, closeWindow, showPhotographiesWindow } from "../actions";

class PostitWindowTwo extends React.Component {
  render() {
    const postitData = getWindowsProps(
      this.props.windows.windowsData,
      "PostitTwo"
    );

    const windowStyle = {
      position: "absolute",
      top: postitData.position.top,
      left: postitData.position.left,
      zIndex: postitData.z
    };

    const windowsWidth = {
      width: postitData.width
    };

    return (
      <Draggable
        bounds="body"
        onMouseDown={() => this.props.updateIndex(postitData)}
      >
        <div style={windowStyle} className={postitData.show ? "" : "hidden"}>
          <div className="work400" style={windowsWidth}>
            <div className="postit-two-container">
              <div className="header"></div>
              <p>1 cup salted butter* softened</p>
              <p>1 cup white (granulated) sugar</p>
              <p>1 cup light brown sugar packed</p>
              <p>2 tsp pure vanilla extract</p>
              <p> 2 large eggs</p>
              <p> 3 cups all-purpose flour</p>
              <p> 1 tsp baking soda</p>
              <p> ½ tsp baking powder</p>
              <p> 1 tsp sea salt***</p>
              <p> 2 cups chocolate chips (or chunks, or chopped chocolate)</p>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  { updateIndex, closeWindow, showPhotographiesWindow }
)(PostitWindowTwo);
