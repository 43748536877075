import React from 'react';
import Draggable from 'react-draggable';
import lines from '../images/desktop/windows/lines.png';
import close from '../images/desktop/windows/x.png';
import minim from '../images/desktop/windows/minim.png';
import { connect } from 'react-redux';
import { getWindowsProps } from '../utils/helpers';
import { updateIndex, closeWindow } from '../actions';

class LegalPage extends React.Component {
  render() {
    const legalData = getWindowsProps(this.props.windows.windowsData, 'Legal');

    const windowStyle = {
      position: 'absolute',
      top: legalData.position.top,
      left: legalData.position.left,
      zIndex: legalData.z
    };

    const linesBg = {
      backgroundColor: '#F0EDED',
      backgroundImage: `url(${lines})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '90% 45%'
    };

    const windowsWidth = {
      width: legalData.width
    };

    return (
      <Draggable
        bounds="body"
        onMouseDown={() => this.props.updateIndex(legalData)}
      >
        <div style={windowStyle} className={legalData.show ? '' : 'hidden'}>
          <div className="father work600" style={windowsWidth}>
            <div style={linesBg} className="topbar">
              <div className="child close">
                <img
                  src={close}
                  onClick={() =>
                    this.props.closeWindow({ ...legalData, show: false })
                  }
                  alt=""
                />
              </div>
              <div className="child title">
                <span>Legal</span>
              </div>
              <div className="child maximise">
                <img src={minim} alt="" />
              </div>
            </div>
            <div className="legal-container work400">
              <div>
                <p className="work600">Impressum</p>
                <br></br>
                <p>Angaben gemäß § 5 TMG:</p>
                <p>Eleni Gkelameri</p>
                <p>Visual Artist</p>
                <p>Sitz: Berlin</p>
                <br></br>
                <p className="work600">Kontakt</p>
                <p>Telefon: +4915733698135</p>
                <p>Email: e.gkelameri@gmail.com</p>
                <br></br>
                <p className="work600">Umsatzsteuer</p>
                <p>
                  Umsatzsteuer-Identifikationsnummer gemäß §27 a
                  Umsatzsteuergesetz:
                </p>
                <p>UST-IDNR: DE325915988</p>
                <br></br>
                <br></br>
                <span className="tiny-mofos">
                  <p className="work600">Haftungsausschluss</p>
                  <br></br>
                  <p className="work600">Haftung für Inhalte</p>
                  <p>
                    Die Inhalte unserer Seiten wurden mit größter Sorgfalt
                    erstellt. Für die Richtigkeit, Vollständigkeit und
                    Aktualität der Inhalte können wir jedoch keine Gewähr
                    übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG
                    für eigene Inhalte auf diesen Seiten nach den allgemeinen
                    Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                    Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                    gespeicherte fremde Informationen zu überwachen oder nach
                    Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                    hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                    Nutzung von Informationen nach den allgemeinen Gesetzen
                    bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                    jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                    Rechtsverletzung möglich. Bei Bekanntwerden von
                    entsprechenden Rechtsverletzungen werden wir diese Inhalte
                    umgehend entfernen.
                  </p>
                  <br></br>
                  <p className="work600">Haftung für Links</p>
                  <p>
                    Unser Angebot enthält Links zu externen Webseiten Dritter,
                    auf deren Inhalte wir keinen Einfluss haben. Deshalb können
                    wir für diese fremden Inhalte auch keine Gewähr übernehmen.
                    Für die Inhalte der verlinkten Seiten ist stets der
                    jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                    Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
                    auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                    waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
                    permanente inhaltliche Kontrolle der verlinkten Seiten ist
                    jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
                    nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                    werden wir derartige Links umgehend entfernen.
                  </p>
                  <br></br>
                  <p className="work600">Urheberrecht</p>
                  <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke
                    auf diesen Seiten unterliegen dem deutschen Urheberrecht.
                    Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
                    der Verwertung außerhalb der Grenzen des Urheberrechtes
                    bedürfen der schriftlichen Zustimmung des jeweiligen Autors
                    bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
                    für den privaten, nicht kommerziellen Gebrauch gestattet.
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                    erstellt wurden, werden die Urheberrechte Dritter beachtet.
                    Insbesondere werden Inhalte Dritter als solche
                    gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um
                    einen entsprechenden Hinweis. Bei Bekanntwerden von
                    Rechtsverletzungen werden wir derartige Inhalte umgehend
                    entfernen.
                  </p>
                  <br></br>
                  <p className="work600">Datenschutz</p>
                  <p>
                    Die Nutzung unserer Webseite ist in der Regel ohne Angabe
                    personenbezogener Daten möglich. Soweit auf unseren Seiten
                    personenbezogene Daten (beispielsweise Name, Anschrift oder
                    eMail-Adressen) erhoben werden, erfolgt dies, soweit
                    möglich, stets auf freiwilliger Basis. Diese Daten werden
                    ohne Ihre ausdrückliche Zustimmung nicht an Dritte
                    weitergegeben. Wir weisen darauf hin, dass die
                    Datenübertragung im Internet (z.B. bei der Kommunikation per
                    E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
                    Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                    möglich. Der Nutzung von im Rahmen der Impressumspflicht
                    veröffentlichten Kontaktdaten durch Dritte zur Übersendung
                    von nicht ausdrücklich angeforderter Werbung und
                    Informationsmaterialien wird hiermit ausdrücklich
                    widersprochen. Die Betreiber der Seiten behalten sich
                    ausdrücklich rechtliche Schritte im Falle der unverlangten
                    Zusendung von Werbeinformationen, etwa durch Spam-Mails,
                    vor.
                  </p>
                  <br></br>
                  <p className="work600">Google Analytics</p>
                  <p>
                    Diese Website benutzt Google Analytics, einen
                    Webanalysedienst der Google Inc. (''Google''). Google
                    Analytics verwendet sog. ''Cookies'', Textdateien, die auf
                    Ihrem Computer gespeichert werden und die eine Analyse der
                    Benutzung der Website durch Sie ermöglicht. Die durch den
                    Cookie erzeugten Informationen über Ihre Benutzung dieser
                    Website (einschließlich Ihrer IP-Adresse) wird an einen
                    Server von Google in den USA übertragen und dort
                    gespeichert. Google wird diese Informationen benutzen, um
                    Ihre Nutzung der Website auszuwerten, um Reports über die
                    Websiteaktivitäten für die Websitebetreiber
                    zusammenzustellen und um weitere mit der Websitenutzung und
                    der Internetnutzung verbundene Dienstleistungen zu
                    erbringen. Auch wird Google diese Informationen
                    gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
                    vorgeschrieben oder soweit Dritte diese Daten im Auftrag von
                    Google verarbeiten. Google wird in keinem Fall Ihre
                    IP-Adresse mit anderen Daten der Google in Verbindung
                    bringen. Sie können die Installation der Cookies durch eine
                    entsprechende Einstellung Ihrer Browser Software verhindern;
                    wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                    gegebenenfalls nicht sämtliche Funktionen dieser Website
                    voll umfänglich nutzen können. Durch die Nutzung dieser
                    Website erklären Sie sich mit der Bearbeitung der über Sie
                    erhobenen Daten durch Google in der zuvor beschriebenen Art
                    und Weise und zu dem zuvor benannten Zweck einverstanden.
                  </p>
                  <br></br>
                  <p>
                    Generated by{' '}
                    <a
                      href="https://www.impressum-generator.de/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Impressum Generator
                    </a>
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { updateIndex, closeWindow })(
  LegalPage
);
