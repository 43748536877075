import React from 'react';
import returnButton from '../images/desktop/windows/return.svg';
import { connect } from 'react-redux';
import { getWindowsProps, getGraphics } from '../utils/helpers';
import { updateIndex, closeWindow, showGraphics } from '../actions';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import renderHTML from 'react-render-html';
import Links from './Links';

class GraphicsPage extends React.Component {
  handleScroll = e => {
    let win = document.querySelectorAll('.graphics-container')[0];
    let sid = document.querySelectorAll('.graphics-text-overlay')[0];
    if (win.scrollTop < 100) sid.classList.remove('slide-top');
    else sid.classList.add('slide-top');
  };

  scrollBack = () => {
    this.parallax.scrollTo(0);
  };

  render() {
    const graphicsData = getWindowsProps(
      this.props.windows.windowsData,
      'GraphicsPage'
    );
    const imagesData = getGraphics(graphicsData.content);
    let renderedImages;

    const parallaxLengths = {
      brandarmies: 6,
      kolibri: 7,
      laharLimbo: 2,
      laharCannibal: 3,
      collage: 2,
      website: 4
    };

    if (imagesData) {
      renderedImages = imagesData.data.map((file, i) => {
        let url = require(`../images/desktop/data/graphics/${graphicsData.content}/${file.url}`);
        return (
          <ParallaxLayer
            className={file.containerClass}
            offset={file.offset}
            speed={file.speed}
            key={i}
          >
            <img src={url} alt="" className={file.imageClass} />
          </ParallaxLayer>
        );
      });
    }

    if (!graphicsData.show) {
      renderedImages = '';
    }
    return (
      <div
        className={
          graphicsData.show
            ? 'fullScreen black my-graphics'
            : 'fullScreen black my-graphics hidden'
        }
      >
        <div className="return" onClick={this.scrollBack}>
          <img
            src={returnButton}
            alt=""
            onClick={() =>
              this.props.closeWindow({
                ...graphicsData,
                show: false,
                content: ''
              })
            }
          />
        </div>
        {imagesData ? (
          <Parallax
            className="parallax-parent-con-black"
            ref={ref => (this.parallax = ref)}
            pages={parallaxLengths[graphicsData.content]}
          >
            <ParallaxLayer
              className="parallax-cont"
              offset="0"
              speed=".1"
              key="lol"
            >
              <div className="parallax-text-container parallax-center-text ">
                <div className="title work600">
                  {renderHTML(imagesData.desc.title)}
                </div>
                <div className="text work400">
                  {renderHTML(imagesData.desc.text)}
                </div>
                <div className="links">
                  <Links links={imagesData.links} />
                </div>
              </div>
            </ParallaxLayer>
            {renderedImages}
          </Parallax>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
  updateIndex,
  closeWindow,
  showGraphics
})(GraphicsPage);
