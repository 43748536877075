import React from "react";
import { connect } from "react-redux";
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";
import { closeWindow, showPhotographiesWindow, updateIndex } from "../actions";
import returnButton from "../images/desktop/windows/return.svg";
import { getImages, getWindowsProps } from "../utils/helpers";

class PhotographiesPage extends React.Component {
  addClasses = e => {
    let target = e.currentTarget;
    let children = document.getElementsByClassName("image");
    let cls = "shiftLeft";

    for (var i = 0; i < children.length; i++) {
      if (children[i] === target) {
        cls = "shiftRight";
        continue;
      } else children[i].classList.add(cls);
    }
  };

  removeClasses = e => {
    let children = document.getElementsByClassName("image");

    for (var i = 0; i < children.length; i++) {
      children[i].classList.remove("shiftLeft");
      children[i].classList.remove("shiftRight");
    }
  };

  scrollBack = () => {
    this.parallax.scrollTo(0);
  };

  getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  render() {
    const photographiesData = getWindowsProps(
      this.props.windows.windowsData,
      "Photographies"
    );

    // // let pageHeight = 1;

    // if (window.matchMedia("(orientation: portrait)").matches)
    //   pageHeight = "0.4";

    const parallaxLengths = {
      places: 6,
      faces: 9,
      moments: 5,
      cyanotypes: 2,
      daguerreotypes: 2
    };

    const imagesData = getImages(photographiesData.content);
    let renderedImages;

    if (imagesData) {
      renderedImages = imagesData.map((file, i) => {
        let url = require(`../images/desktop/data/images/${photographiesData.content}/${file.url}`);
        return (
          <ParallaxLayer
            className={file.containerClass}
            offset={file.offset}
            speed={file.speed}
            key={i}
          >
            <img alt="" src={url} className={file.imageClass} />
          </ParallaxLayer>
        );
      });
    }

    if (!photographiesData.show) {
      renderedImages = "";
    }

    return (
      <div
        className={
          photographiesData.show
            ? "fullScreen black"
            : "fullScreen black hidden"
        }
      >
        <div className="return" onClick={this.scrollBack}>
          <img
            alt=""
            src={returnButton}
            onClick={() =>
              this.props.closeWindow({
                ...photographiesData,
                show: false,
                content: ""
              })
            }
          />
        </div>
        {imagesData ? (
          <Parallax
            className="parallax-parent-cont"
            ref={ref => (this.parallax = ref)}
            pages={parallaxLengths[photographiesData.content]}
          >
            {renderedImages}
          </Parallax>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  { updateIndex, closeWindow, showPhotographiesWindow }
)(PhotographiesPage);
