import {
  faLinkedin,
  faVimeoV
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Clock from 'react-live-clock';
import { connect } from 'react-redux';
import { closeWindow, updateIndex } from '../actions';
import battery from '../images/desktop/topbar/battery.png';
import email from '../images/desktop/topbar/email.png';
import logo from '../images/desktop/topbar/logo.svg';
import { getWindowsProps } from '../utils/helpers';

class FolderIcon extends React.Component {
  updateBg = () => {
    if (this.bgIndex > 3) this.bgIndex = 1;
    else this.bgIndex++;
    const bt = document.getElementsByClassName('app')[0];
    let url = require(`../images/background/${this.bgIndex}.png`);
    
    bt.style.backgroundImage = `url(${url})`;
    bt.style.backgroundRepeat = `no-repeat`;
    bt.style.backgroundSize = `cover`;
    bt.style.backgroundPosition = `center`;
  };

  updateColors = e => {
    const title = e.target.dataset.title;
    const bd = document.getElementsByTagName('body')[0];
    if (title === 'invert') {
      bd.classList.remove('bw');
      bd.classList.add('invert');
    } else if (title === 'bw') {
      bd.classList.remove('invert');
      bd.classList.add('bw');
    } else if (title === 'reset') {
      bd.classList.remove('bw');
      bd.classList.remove('invert');
    }
  };

  render() {
    const notebookData = getWindowsProps(
      this.props.windows.windowsData,
      'Notebook'
    );
    const todoData = getWindowsProps(this.props.windows.windowsData, 'Todo');
    // const legalData = getWindowsProps(this.props.windows.windowsData, 'Legal');
    this.bgIndex = 1;
    // let cvUrl = require(`../images/Eleni_Gkelameri_CV_2022.pdf`);

    return (
      <div className="nav">
        <div className="left">
          <div className="icon">
            <img src={logo} alt="Amagun" />
          </div>
          <div className="name work600">
            <p>Eleni Gkelameri</p>
          </div>
          <div className="dropdown about work400">
            <div className="dropbtn">
              <p>About</p>
            </div>
            <div className="dropdown-content">
              <a href="#" onClick={() => this.props.updateIndex(notebookData)}>
                About me
              </a>
           
              <a href="#" onClick={() => this.props.updateIndex(todoData)}>
                ToDo List
              </a>
      
            </div>
          </div>
          <div className="dropdown about work400">
            <div className="dropbtn">
              <p>Contact</p>
            </div>
            <div className="dropdown-content">
              <a href="#">New Email...</a>
            </div>
          </div>
          <div className="dropdown about work400">
            <div className="dropbtn">
              <p>Edit</p>
            </div>
            <div className="dropdown-content">
              <a onClick={this.updateBg}>Update background</a>
              <a onClick={this.updateColors} data-title="invert">
                Invert
              </a>
              {/* <a onClick={this.updateColors} data-title="bw">Black & White</a> */}
              <a onClick={this.updateColors} data-title="reset">
                Reset
              </a>
            </div>
          </div>
          <div className="dropdown about work400 help-button">
            <div className="dropbtn">
              <p>Help</p>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="email">
            <a href="mailto:e.gkelameri@gmail.com">
              <img src={email} alt="Email" />
            </a>
          </div>
          <div className="vimeo">
            <a
              href="https://vimeo.com/gkelameri"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faVimeoV} />
            </a>
          </div>
          {/* <div className="tumblr">
            <a
              href="https://apersonal.tumblr.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faTumblr} />
            </a>
          </div> */}
          <div className="linkedin">
            <a
              href="https://www.linkedin.com/in/eleni-gkelameri-112986b3/"
              target="blank"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
          <div className="battery">
            <img src={battery} alt="Battery" />
          </div>
          <div className="time work400">
            <Clock format={'HH:mm'} ticking={true} timezone={'Europe/Berlin'} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { updateIndex, closeWindow })(
  FolderIcon
);
