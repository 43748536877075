const AnimationsData = {
  motionDesignSchool: {
    data: [
      {
        url: '2.png',
        speed: '1',
        offset: '0.7',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center'
      },
      {
        url: '3.png',
        speed: '.1',
        offset: '1.2',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-center-bigger'
      },
      {
        url: '1.png',
        speed: '.2',
        offset: '2',
        containerClass: 'parallax-cont',
        imageClass: 'parallax-right-bigger'
      }
    ],
    desc: {
      title:
        'Motion Design School / Science of Character Animation by Markus Magnusson',
      text:
        'Currently taking part in this drip course on Character Animation.<br>You can check out some of my progress below.<br>More to come soon.'
    },
    links: [
      {
        type: 'website',
        url:
          'https://motiondesign.school/products/science-of-character-animation'
      }
    ]
  }
};

export default AnimationsData;
