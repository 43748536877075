import React from "react";
import { connect } from "react-redux";
import { closeWindow, updateIndex } from "../actions";
import returnButton from "../images/desktop/windows/return.svg";
import { getWindowsProps } from "../utils/helpers";
import Carousel from "./Carousel";

class RedButtonWindow extends React.Component {
  render() {
    const redData = getWindowsProps(
      this.props.windows.windowsData,
      "Red Button"
    );

    return (
      <div
        id="rtr"
        className={
          redData.show
            ? "fullScreen my-projects show-red-button-animation"
            : "fullScreen my-projects hidden"
        }
      >
        <div className="return">
          <img
            src={returnButton}
            alt=""  
            onClick={() => this.props.closeWindow({ ...redData, show: false })}
          />
        </div>
        <Carousel></Carousel>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  { updateIndex, closeWindow }
)(RedButtonWindow);
