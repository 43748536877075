import React from 'react';
import returnButton from '../images/desktop/windows/return.svg';
import { connect } from 'react-redux';
import { getWindowsProps, getAnimations } from '../utils/helpers';
import { updateIndex, closeWindow, showVideosWindow } from '../actions';
import ReactPlayer from 'react-player';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import Links from './Links';
import ReactHtmlParser from 'react-html-parser';

class AnimationsPage extends React.Component {
  onPlay = () => {
    let sidebar = document.getElementsByClassName('on-point-animation')[0];
    let video = document.getElementsByTagName('video')[0];
    video.controlsList = 'nodownload';
    sidebar.classList.add('slide-left');
  };

  onPause = () => {
    let sidebar = document.getElementsByClassName('on-point-animation')[0];
    sidebar.classList.remove('slide-left');
  };

  slideToTheRight = () => {
    let sidebar = document.getElementsByClassName('on-point-animation')[0];
    sidebar.classList.remove('slide-left');
  };

  slidetToTheLeft = () => {
    let sidebar = document.getElementsByClassName('on-point-animation')[0];
    sidebar.classList.add('slide-left');
  };

  ref = player => {
    this.player = player;
  };

  render() {
    const animationsData = getWindowsProps(
      this.props.windows.windowsData,
      'AnimationsPage'
    );
    const data = getAnimations(animationsData.content);
    let url, thumb;
    let renderedImages;

    const parallaxLengths = {
      kolibri: 2,
      motionDesignSchool: 3
    };

    if (data && animationsData && animationsData.content === 'kolibri') {
      url = require(`../images/desktop/data/animations/${animationsData.content}/${data.data[0].url}`);
      thumb = require(`../images/desktop/data/animations/${animationsData.content}/${data.data[0].thumb}`);
      let controls = {
        playing: true,
        light: thumb,
        width: '100%',
        height: '100%',
        showControls: true,
        url: url,
        thumb: thumb
      };

      const onClick = () => {
        this.props.closeWindow({ ...animationsData, show: false });
        this.player.showPreview();
        this.onPause();
      };

      return (
        <div
          className={
            animationsData.show ? 'fullScreen black' : 'fullScreen black hidden'
          }
        >
          <div className="return">
            <img src={returnButton} alt="" onClick={onClick} />
          </div>
          <div className="videoContainer">
            <ReactPlayer
              ref={this.ref}
              className="video-player"
              url={controls.url}
              playing={controls.playing}
              light={controls.light}
              width={controls.width}
              height={controls.height}
              controls={controls.showControls}
              onPlay={this.onPlay}
              onPause={this.onPause}
            />
          </div>
          <div
            className="text-overlay work300"
            onMouseEnter={this.slideToTheRight}
            onMouseLeave={this.slidetToTheLeft}
          >
            <span className="on-point-animation">
              <div className="title">
                <p>{ReactHtmlParser(data.desc.title)}</p>
                <div className="links">
                  <Links links={data.links} />
                </div>
              </div>
              <div className="text">
                <p>{ReactHtmlParser(data.desc.text)}</p>
              </div>
            </span>
          </div>
        </div>
      );
    } else if (
      data &&
      animationsData &&
      animationsData.content === 'motionDesignSchool'
    ) {
      if (data) {
        renderedImages = data.data.map((file, i) => {
          let url = require(`../images/desktop/data/animations/${animationsData.content}/${file.url}`);
          return (
            <ParallaxLayer
              className={file.containerClass}
              offset={file.offset}
              speed={file.speed}
              key={i}
            >
              <img src={url} alt="" className={file.imageClass} />
            </ParallaxLayer>
          );
        });
      }

      return (
        <div
          className={
            animationsData.show
              ? 'fullScreen  white my-animations'
              : 'fullScreen  white my-animations hidden'
          }
        >
          <div className="return" onClick={this.scrollBack}>
            <img
              src={returnButton}
              alt=""
              onClick={() =>
                this.props.closeWindow({
                  ...animationsData,
                  show: false,
                  content: ''
                })
              }
            />
          </div>
          {data ? (
            <Parallax
              className="parallax-parent-cont-white bordered"
              ref={ref => (this.parallax = ref)}
              pages={parallaxLengths[animationsData.content]}
            >
              <ParallaxLayer
                className="parallax-cont"
                offset="0"
                speed=".1"
                key="lol"
              >
                <div className="parallax-text-container parallax-center-text ">
                  <div className="title work600">
                    {ReactHtmlParser(data.desc.title)}
                  </div>
                  <div className="text work400">
                    {ReactHtmlParser(data.desc.text)}
                  </div>
                  <div className="links">
                    <Links links={data.links} />
                  </div>
                </div>
              </ParallaxLayer>
              {renderedImages}
            </Parallax>
          ) : (
            ''
          )}
        </div>
      );
    } else return <div className="hidden"></div>;
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
  updateIndex,
  closeWindow,
  showVideosWindow
})(AnimationsPage);
