import React from 'react'
import Draggable from 'react-draggable'
import { connect } from 'react-redux'
import { closeWindow, showGraphics, updateIndex } from '../actions'
import gif from '../images/ugly.gif'
import { getWindowsProps } from '../utils/helpers'

class Ugly extends React.Component {
    render() {
        const newProjectData = getWindowsProps(
            this.props.windows.windowsData,
            'Ugly'
        )
        // const videosData = getWindowsProps(this.props.windows.windowsData, 'Videos');
        // const photographyData = getWindowsProps(this.props.windows.windowsData, 'Photography');
        // const graphicsData = getWindowsProps(this.props.windows.windowsData, 'Graphics');
        // const animationData = getWindowsProps(this.props.windows.windowsData, 'Animation');

        const windowStyle = {
            position: 'absolute',
            top: newProjectData.position.top,
            left: newProjectData.position.left,
            zIndex: newProjectData.z,
            // width: '300px'
        }

        // const windowsWidth = {
        //     width: newProjectData.width,
        //     height:'fitsContent'
        // }


        return (
            <Draggable
                bounds='body'
                onMouseDown={() => this.props.updateIndex(newProjectData)}>
                <div className='ugly' style={windowStyle}>
                    <div className='ugly' >
                        <img src={gif} alt='' />
                    </div>
                </div>
            </Draggable>
        )
    }
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {
    updateIndex,
    showGraphics,
    closeWindow,
})(Ugly)
