import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'
import { closeWindow, showVideosWindow, updateIndex } from '../actions'
import returnButton from '../images/desktop/windows/return.svg'
import { getVideos, getWindowsProps } from '../utils/helpers'
import Links from './Links'

class VideosPage extends React.Component {
    onPlay = () => {
        let sidebar = document.getElementsByClassName('on-point')[0]
        sidebar.classList.add('slide-left')
    }

    onPause = () => {
        let sidebar = document.getElementsByClassName('on-point')[0]
        if (sidebar) sidebar.classList.remove('slide-left')
    }

    onPlayHippo = () => {
        let video = document.getElementsByTagName('video')[0]
        video.controlsList = 'nodownload'
    }

    slideToTheRight = () => {
        let sidebar = document.getElementsByClassName('on-point')[0]
        sidebar.classList.remove('slide-left')
    }

    slidetToTheLeft = () => {
        let sidebar = document.getElementsByClassName('on-point')[0]
        sidebar.classList.add('slide-left')
    }

    ref = (player) => {
        this.player = player
    }

    render() {
        const videosData = getWindowsProps(
            this.props.windows.windowsData,
            'VideosPage'
        )
        const data = getVideos(videosData.content)
        let url,
            thumb,
            urlOne,
            urlTwo,
            urlThree,
            urlInstallation,
            thumbInstallation,
            urlPartTwo,
            urlPartTwoOne,
            urlPoster,
            urlPartTwoTwo,
            urlPartTwoThree,
            thumbPartTwo
        if (data && videosData) {
            if (
                videosData.content === 'hippocampus' ||
                videosData.content === 'pantaflix' ||
                videosData.content === 'laharLimbo'
            )
                url = data.videoUrl
            else if (videosData.content === 'showreel')
                url = require(`../images/desktop/data/videos/showreel/showreel.mp4`)
            else url = require(`../images/desktop/data/videos/${data.videoUrl}`)
            thumb = require(`../images/desktop/data/videos/${data.thumb}`)

            if (videosData.content === 'hippocampus') {
                urlOne = require(`../images/desktop/data/videos/${data.three.urlOne}`)
                urlTwo = require(`../images/desktop/data/videos/${data.three.urlTwo}`)
                urlThree = require(`../images/desktop/data/videos/${data.three.urlThree}`)
                urlPartTwoOne = require(`../images/desktop/data/videos/${data.threePartTwo.urlOne}`)
                urlPoster = require(`../images/desktop/data/videos/${data.poster}`)
                urlPartTwoTwo = require(`../images/desktop/data/videos/${data.threePartTwo.urlTwo}`)
                urlPartTwoThree = require(`../images/desktop/data/videos/${data.threePartTwo.urlThree}`)
                urlInstallation = require(`../images/desktop/data/videos/${data.installation.url}`)
                thumbInstallation = require(`../images/desktop/data/videos/${data.installation.thumb}`)
                urlPartTwo = data.partTwo.url
                thumbPartTwo = require(`../images/desktop/data/videos/${data.partTwo.thumb}`)
            }

            console.log(videosData)
            let controls = {
                playing: true,
                light: thumb,
                width: '100%',
                height: '100%',
                showControls: true,
                url: url,
                playsinline: true,
            }

            let controlsHippo = {
                playing: true,
                light: thumb,
                width: '100%',
                height: '500px',
                showControls: true,
                url: url,
                playsinline: true,
            }

            let controlsHippoInstallation = {
                playing: true,
                light: thumbInstallation,
                width: '80%',
                height: '500px',
                showControls: true,
                url: urlInstallation,
                playsinline: true,
            }

            let controlsPartTwo = {
                playing: true,
                light: thumbPartTwo,
                width: '80%',
                height: '500px',
                showControls: true,
                url: urlPartTwo,
                playsinline: true,
            }

            const onClick = () => {
                this.props.closeWindow({ ...videosData, show: false })
                this.player.showPreview()
                this.onPause()
            }

            const output = () => {
                if (videosData.content !== 'hippocampus')
                    return (
                        <span>
                            <div className='videoContainer'>
                                <ReactPlayer
                                    ref={this.ref}
                                    className='video-player'
                                    url={controls.url}
                                    playing={controls.playing}
                                    light={controls.light}
                                    width={controls.width}
                                    height={controls.height}
                                    controls={controls.showControls}
                                    onPlay={this.onPlay}
                                    onPause={this.onPause}
                                    playsinline={controls.playsinline}
                                />
                            </div>
                            <div
                                className='text-overlay work300'
                                onMouseEnter={this.slideToTheRight}
                                onMouseLeave={this.slidetToTheLeft}>
                                <span className='on-point'>
                                    <div className='title'>
                                        <p>{ReactHtmlParser(data.title)}</p>
                                        <div className='links'>
                                            <Links links={data.links} />
                                        </div>
                                    </div>
                                    <div className='text'>
                                        <p>{ReactHtmlParser(data.text)}</p>
                                    </div>
                                </span>
                            </div>
                        </span>
                    )
                else
                    return (
                        <div>
                            <div className='hippo-container'>
                                <div className='child'>
                                    <div className='left'>
                                        <div className='text work300'>
                                            <div className='title'>
                                                <p>
                                                    {ReactHtmlParser(
                                                        data.title
                                                    )}
                                                </p>
                                            </div>
                                            <div className='text'>
                                                <p>
                                                    {ReactHtmlParser(data.text)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <ReactPlayer
                                            ref={this.ref}
                                            className='video-player'
                                            url={controlsHippo.url}
                                            playing={controlsHippo.playing}
                                            light={controlsHippo.light}
                                            width={controlsHippo.width}
                                            height={controlsHippo.height}
                                            controls={
                                                controlsHippo.showControls
                                            }
                                            onPlay={this.onPlayHippo}
                                            playsinline={
                                                controlsHippo.playsinline
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='child'>
                                    <div className='three-in-a-row'>
                                        <div className='child'>
                                            <img alt='' src={urlOne}></img>
                                        </div>
                                        <div className='child'>
                                            <img alt='' src={urlTwo}></img>
                                        </div>
                                        <div className='child'>
                                            <img alt='' src={urlThree}></img>
                                        </div>
                                    </div>
                                </div>
                                <div className='child'>
                                    <ReactPlayer
                                        ref={this.ref}
                                        className='video-player'
                                        url={controlsHippoInstallation.url}
                                        playing={
                                            controlsHippoInstallation.playing
                                        }
                                        light={controlsHippoInstallation.light}
                                        width={controlsHippoInstallation.width}
                                        height={
                                            controlsHippoInstallation.height
                                        }
                                        controls={
                                            controlsHippoInstallation.showControls
                                        }
                                        onPlay={this.onPlayHippo}
                                        playsinline={
                                            controlsHippoInstallation.playsinline
                                        }
                                    />
                                </div>
                                <div className='child'>
                                    <ReactPlayer
                                        ref={this.ref}
                                        className='video-player'
                                        url={controlsPartTwo.url}
                                        playing={controlsPartTwo.playing}
                                        light={controlsPartTwo.light}
                                        width={controlsPartTwo.width}
                                        height={controlsPartTwo.height}
                                        controls={controlsPartTwo.showControls}
                                        onPlay={this.onPlayHippo}
                                        playsinline={
                                            controlsPartTwo.playsinline
                                        }
                                    />
                                </div>
                                <div className='child'>
                                    <div className='three-in-a-row'>
                                        <div className='child'>
                                            <img
                                                alt=''
                                                src={urlPartTwoOne}></img>
                                        </div>
                                        <div className='child'>
                                            <img
                                                alt=''
                                                src={urlPartTwoTwo}></img>
                                        </div>
                                        <div className='child'>
                                            <img
                                                alt=''
                                                src={urlPartTwoThree}></img>
                                        </div>
                                    </div>
                                </div>
                                <div className='child'>
                                    <div className='one-in-a-row'>
                                        <div className='child bordered'>
                                            <img alt='' src={urlPoster}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
            }

            return (
                <div
                    className={
                        videosData.show
                            ? 'fullScreen black'
                            : 'fullScreen black hidden'
                    }>
                    <div className='return'>
                        <img src={returnButton} alt='' onClick={onClick} />
                    </div>
                    {output()}
                </div>
            )
        } else return <div className='hidden'></div>
    }
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {
    updateIndex,
    closeWindow,
    showVideosWindow,
})(VideosPage)
