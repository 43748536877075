import React from "react";
import Draggable from "react-draggable";
import collage from "../images/collage.svg";
import lines from "../images/desktop/windows/lines.png";
import close from "../images/desktop/windows/x.png";
import minim from "../images/desktop/windows/minim.png";
import { connect } from "react-redux";
import { getWindowsProps } from "../utils/helpers";
import { updateIndex, closeWindow, showPhotographiesWindow } from "../actions";
import ReactSVG from "react-svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

class CollageWindow extends React.Component {
  svgLoaded() {
    const moon = document.getElementById("moon");
    const pancake = document.getElementById("pancake");
    const cat = document.getElementById("cat");
    const pasta = document.getElementById("pasta");
    const jelly = document.getElementById("jelly");

    moon.addEventListener("mouseenter", () => {
      const story = document.getElementById("story");
      story.innerHTML = "༼ つ ◕_◕ ༽つ";
      story.classList.remove("hidden");
    });

    moon.addEventListener("mouseleave", () => {
      const story = document.getElementById("story");
      story.classList.add("hidden");
      story.innerHTML = "";
    });

    pancake.addEventListener("mouseenter", () => {
      const story = document.getElementById("story");
      story.innerHTML = "◉_◉";
      story.classList.remove("hidden");
    });

    pancake.addEventListener("mouseleave", () => {
      const story = document.getElementById("story");
      story.classList.add("hidden");
      story.innerHTML = "";
    });

    cat.addEventListener("mouseenter", () => {
      const story = document.getElementById("story");
      story.innerHTML = "ฅ^•ﻌ•^ฅ";
      story.classList.remove("hidden");
    });

    cat.addEventListener("mouseleave", () => {
      const story = document.getElementById("story");
      story.classList.add("hidden");
      story.innerHTML = "";
    });

    pasta.addEventListener("mouseenter", () => {
      const story = document.getElementById("story");
      story.innerHTML = "( ˘ ³˘)♥";
      story.classList.remove("hidden");
    });

    pasta.addEventListener("mouseleave", () => {
      const story = document.getElementById("story");
      story.classList.add("hidden");
      story.innerHTML = "";
    });

    jelly.addEventListener("mouseenter", () => {
      const story = document.getElementById("story");
      story.innerHTML = "(❍ᴥ❍ʋ)";
      story.classList.remove("hidden");
    });

    jelly.addEventListener("mouseleave", () => {
      const story = document.getElementById("story");
      story.classList.add("hidden");
      story.innerHTML = "";
    });
  }

  loading = () => {
    return (
      <div className="loadingCollage">
        <FontAwesomeIcon icon={faSpinner} spin />
        <p>Loading interactive collage</p>
      </div>
    );
  };
  render() {
    const collageData = getWindowsProps(
      this.props.windows.windowsData,
      "Collage"
    );

    const windowStyle = {
      position: "absolute",
      top: collageData.position.top,
      left: collageData.position.left,
      zIndex: collageData.z
    };

    const linesBg = {
      backgroundColor: "#F0EDED",
      backgroundImage: `url(${lines})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "90% 45%"
    };

    const windowsWidth = {
      width: collageData.width
    };

    return (
      <Draggable
        bounds="body"
        onMouseDown={() => this.props.updateIndex(collageData)}
      >
        <div style={windowStyle} className={collageData.show ? "" : "hidden"}>
          <div className="father work400" style={windowsWidth}>
            <div style={linesBg} className="topbar">
              <div className="child close">
                <img
                  src={close}
                  onClick={() =>
                    this.props.closeWindow({ ...collageData, show: false })
                  }
                  alt=""
                />
              </div>
              <div className="child title collage-title">
                <span>Photography</span>
              </div>
              <div className="child maximise">
                <img src={minim} alt="" />
              </div>
            </div>
            {/* <div className="bar">
                            <div className="child items"><p>5 items</p></div>
                            <div className="child disk"><p>3 GB in disk</p></div>
                            <div className="child available"><p>11 MB available</p></div>
                        </div> */}
            <div className="collage-container">
              <div className="image">
                <ReactSVG
                  loading={this.loading}
                  src={collage}
                  afterInjection={this.svgLoaded}
                />
              </div>
              <div id="story" className="hidden"></div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  { updateIndex, closeWindow, showPhotographiesWindow }
)(CollageWindow);
