import React from 'react';
import Draggable from 'react-draggable';
import graphic from '../images/desktop/items/graphic.png';
import lines from '../images/desktop/windows/lines.png';
import close from '../images/desktop/windows/x.png';
import minim from '../images/desktop/windows/minim.png';
import { connect } from 'react-redux';
import { getWindowsProps } from '../utils/helpers';
import { updateIndex, closeWindow, showGraphics } from '../actions';

class GraphicsWindow extends React.Component {
  render() {
    const graphicsData = getWindowsProps(
      this.props.windows.windowsData,
      'Graphics'
    );

    const filesInfo = [
      { title: 'laharLimbo', text: 'lahar berlin #5 limbo' },
      { title: 'laharCannibal', text: 'lahar berlin #4 cannibal' },
      { title: 'brandarmies', text: 'brandarmies' },
      { title: 'website', text: 'website' },
      { title: 'kolibri', text: 'kolibri games' }
      // { title: 'collage', text: 'experiments' }
    ];

    const windowStyle = {
      position: 'absolute',
      top: graphicsData.position.top,
      left: graphicsData.position.left,
      zIndex: graphicsData.z
    };

    const linesBg = {
      backgroundColor: '#F0EDED',
      backgroundImage: `url(${lines})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '85% 45%'
    };

    const renderedFiles = filesInfo.map(file => {
      return (
        <div
          className="files"
          key={file.title}
          onClick={() => this.props.showGraphics(file.title)}
        >
          <img src={graphic} alt="" />
          <span>{file.text}</span>
        </div>
      );
    });

    const windowsWidth = {
      width: graphicsData.width
    };

    return (
      <Draggable
        bounds="body"
        onMouseDown={() => this.props.updateIndex(graphicsData)}
      >
        <div style={windowStyle} className={graphicsData.show ? '' : 'hidden'}>
          <div className="mother work600" style={windowsWidth}>
            <div style={linesBg} className="topbar">
              <div className="child close">
                <img
                  src={close}
                  onClick={() =>
                    this.props.closeWindow({ ...graphicsData, show: false })
                  }
                  alt=""
                />
              </div>
              <div className="child title">
                <span>Graphic Design & Illustration</span>
              </div>
              <div className="child maximise">
                <img src={minim} alt="" />
              </div>
            </div>
            <div className="bar">
              <div className="child items">
                <p>5 items</p>
              </div>
              <div className="child disk">
                <p>3 GB in disk</p>
              </div>
              <div className="child available">
                <p>11 MB available</p>
              </div>
            </div>
            <div className="doc-container work400">
              <div className="list">{renderedFiles}</div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
  updateIndex,
  closeWindow,
  showGraphics
})(GraphicsWindow);
