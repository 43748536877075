import imagesData from "../data/ImagesData.js";
import videosData from "../data/VideosData.js";
import graphicsData from "../data/GraphicsData.js";
import animationsData from "../data/AnimationsData.js";

export function handleZIndexUpdate(state, title) {
  let topLayer = 0;

  state.map(window => {
    if (window.z >= topLayer) topLayer = window.z;
  });

  state.map(window => {
    if (window.title === title) {
      window.z = topLayer + 1;
      window.show = true;
    }
  });

  return state;
}

export function getWindowsProps(arr, title) {
  let res;
  arr.map(window => {
    if (window.title === title) {
      res = window;
    }
  });
  return res;
}

export function handleCloseWindow(state, title) {
  state.map(window => {
    if (window.title === title) {
      window.show = false;
    }
  });

  return state;
}

export function handleShowFinalWindow(state, content, title) {
  state.map(window => {
    if (window.title === title) {
      window.show = true;
      window.content = content;
    }
  });

  return state;
}

export function getImages(title) {
  let res;
  Object.keys(imagesData).forEach(function(key) {
    if (key === title) {
      res = imagesData[key];
    }
  });

  return res;
}

export function getAnimations(title) {
  let res;
  Object.keys(animationsData).forEach(function(key) {
    if (key === title) {
      res = animationsData[key];
    }
  });

  return res;
}

export function getGraphics(title) {
  let res;
  Object.keys(graphicsData).forEach(function(key) {
    if (key === title) {
      res = graphicsData[key];
    }
  });

  return res;
}

export function getVideos(title) {
  let res;

  Object.keys(videosData).forEach(function(key) {
    if (key === title) {
      res = videosData[key];
    }
  });

  return res;
}
