import {
  faCheckSquare,
  faSquare
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Draggable from "react-draggable";
import { connect } from "react-redux";
import { closeWindow, updateIndex } from "../actions";
import lines from "../images/desktop/windows/lines.png";
import minim from "../images/desktop/windows/minim.png";
import close from "../images/desktop/windows/x.png";
import { getWindowsProps } from "../utils/helpers";

class TodoPage extends React.Component {
  takeMeToCourse = () => {
    let url = "https://motiondesign.school";
    window.open(url, "_blank");
  };
  render() {
    const todoData = getWindowsProps(this.props.windows.windowsData, "Todo");

    const windowStyle = {
      position: "absolute",
      top: todoData.position.top,
      left: todoData.position.left,
      zIndex: todoData.z
    };

    const linesBg = {
      backgroundColor: "#F0EDED",
      backgroundImage: `url(${lines})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "90% 45%"
    };

    const windowsWidth = {
      width: todoData.width
    };

    return (
      <Draggable
        bounds="body"
        onMouseDown={() => this.props.updateIndex(todoData)}
      >
        <div style={windowStyle} className={todoData.show ? "" : "hidden"}>
          <div className="father work600" style={windowsWidth}>
            <div style={linesBg} className="topbar">
              <div className="child close">
                <img
                  src={close}
                  onClick={() =>
                    this.props.closeWindow({ ...todoData, show: false })
                  }
                  alt=""
                />
              </div>
              <div className="child title">
                <span>ToDo List</span>
              </div>
              <div className="child maximise">
                <img src={minim} alt="" />
              </div>
            </div>
            {/* <div className="bar">
                            <div className="child items"><p>5 items</p></div>
                            <div className="child disk"><p>3 GB in disk</p></div>
                            <div className="child available"><p>11 MB available</p></div>
                        </div> */}
            <div className="todo-container work400">
              <div className="list">
                <div className="files done">
                  <FontAwesomeIcon icon={faCheckSquare} />
                  <span>Finish website 1.0</span>
                </div>
                {/* <div className="files done">
                  <FontAwesomeIcon icon={faCheckSquare} />
                  <span>Start Animation course</span>
                  <FontAwesomeIcon
                    icon={faExternalLinkSquareAlt}
                    onClick={this.takeMeToCourse}
                  />
                </div> */}
                <div className="files done">
                  <FontAwesomeIcon icon={faCheckSquare} />
                  <span>Make an animated short</span>
                </div>  
                <div className="files">
                  <FontAwesomeIcon icon={faSquare} />
                  <span>Figure things out</span>
                </div>
                <div className="files done">
                  <FontAwesomeIcon icon={faCheckSquare} />
                  <span>Buy a chair</span>
                </div>
                <div className="files done">
                  <FontAwesomeIcon icon={faCheckSquare} />
                  <span>Go to the vet</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  { updateIndex, closeWindow }
)(TodoPage);
