import React from 'react';
import Draggable from 'react-draggable';
import floppy from '../images/desktop/items/video.png';
import lines from '../images/desktop/windows/lines.png';
import close from '../images/desktop/windows/x.png';
import minim from '../images/desktop/windows/minim.png';
import { connect } from 'react-redux';
import { getWindowsProps } from '../utils/helpers';
import { updateIndex, closeWindow, showAnimations } from '../actions';

class AnimationsWindow extends React.Component {
  render() {
    const animationData = getWindowsProps(
      this.props.windows.windowsData,
      'Animation'
    );

    const filesInfo = [
      { title: 'motion design school', path: 'motionDesignSchool' }
    ];

    const windowStyle = {
      position: 'absolute',
      top: animationData.position.top,
      left: animationData.position.left,
      zIndex: animationData.z
    };

    const linesBg = {
      backgroundColor: '#F0EDED',
      backgroundImage: `url(${lines})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '85% 45%'
    };

    const renderedFiles = filesInfo.map(file => {
      return (
        <div
          className="files"
          key={file.title}
          onClick={() => this.props.showAnimations(file.path)}
        >
          <img src={floppy} alt="" />
          <span>{file.title}</span>
        </div>
      );
    });

    const windowsWidth = {
      width: animationData.width
    };

    return (
      <Draggable
        bounds="body"
        onMouseDown={() => this.props.updateIndex(animationData)}
      >
        <div style={windowStyle} className={animationData.show ? '' : 'hidden'}>
          <div className="mother work600" style={windowsWidth}>
            <div style={linesBg} className="topbar">
              <div className="child close">
                <img
                  src={close}
                  onClick={() =>
                    this.props.closeWindow({ ...animationData, show: false })
                  }
                  alt=""
                />
              </div>
              <div className="child title">
                <span>Animation & Motion Design</span>
              </div>
              <div className="child maximise">
                <img src={minim} alt="" />
              </div>
            </div>
            <div className="bar">
              <div className="child items">
                <p>2 items</p>
              </div>
              <div className="child disk">
                <p>3 GB in disk</p>
              </div>
              <div className="child available">
                <p>11 MB available</p>
              </div>
            </div>
            <div className="doc-container work400">
              <div className="list">{renderedFiles}</div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
  updateIndex,
  closeWindow,
  showAnimations
})(AnimationsWindow);
