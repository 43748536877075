const videosData = {
    // showreel: {
    //     videoUrl: 'https://vimeo.com/359848871',
    //     title: 'Showreel',
    //     text: 'A collection of my recent projects.',
    //     thumb: 'showreel/thumb.jpg',
    //     links: [],
    // },
    hippocampus: {
        videoUrl: 'https://vimeo.com/360840188',
        title: 'Hippocampus',
        text: "The brain forms and stores memories in the hippocampus. This small organ is responsible for forming long-term memories, connecting one memory to another and attaching emotions and senses to each of them. Memory is not working as a video recorder and it is not an objective retrieval of former events, but a process of active production in every new moment of remembering. It is constantly rebuilding itself, re-creating and altering the facts. Hippocampus is an attempt of creating an audiovisual representation of memory retrieval while mind-wandering or dreaming.<br><br>In collaboration with <a href='https://www.instagram.com/_the_ramen_noodle_/' target='_blank'>the ramen noodle</a>.",
        thumb: 'hippocampus/thumb.jpg',
        three: {
            urlOne: 'hippocampus/screens/1.jpg',
            urlTwo: 'hippocampus/screens/2.jpg',
            urlThree: 'hippocampus/screens/3.jpg',
        },
        installation: {
            url: 'hippocampus/installation.mp4',
            thumb: 'hippocampus/inthumb.png',
        },
        partTwo: {
            url: 'https://vimeo.com/360860048',
            thumb: 'hippocampus/partTwo/partTwoThumb.png',
        },
        threePartTwo: {
            urlOne: 'hippocampus/partTwo/screens/1.png',
            urlTwo: 'hippocampus/partTwo/screens/2.png',
            urlThree: 'hippocampus/partTwo/screens/3.png',
        },
        poster: 'hippocampus/partTwo/screens/poster.png',
        links: [],
    },
    
}

export default videosData
