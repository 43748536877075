import React from "react";
import FolderIcon from "./FolderIcon";
import Navigation from "./Navigation";
import "../css/App.scss";
import logo from "../images/desktop/topbar/logo.svg";

class App extends React.Component {
  render() {
    var mousetimeout;
    var screensaver_active = false;
    var idletime = 10;

    function show_screensaver() {
      let screenSaver = document.getElementsByClassName("screensaver")[0];
      screenSaver.classList.remove("hidden");
      screensaver_active = true;
    }

    function stop_screensaver() {
      let screenSaver = document.getElementsByClassName("screensaver")[0];
      screenSaver.classList.add("hidden");
      screensaver_active = false;
    }

    document.onmousemove = function(e) {
      clearTimeout(mousetimeout);

      if (screensaver_active) {
        stop_screensaver();
      }

      mousetimeout = setTimeout(function() {
        show_screensaver();
      }, 30000 * idletime);
    };

    setTimeout(function() {
      let screenSaver = document.getElementsByClassName("loading")[0];
      screenSaver.classList.add("hidden");
    }, 2000);

    const FolderContainer = {
      width: "100%",
      height: "100%"
    };

    return (
      <div className="app">
        <div className="rotate">
          <img src={logo} alt="Amagun" />
          <p className="work300">Please rotate your device...</p>
        </div>
        <div className="loading">
          <div className="loader">
            <div className="balls"></div>
            <div className="balls"></div>
            <div className="balls"></div>
          </div>
        </div>
        <div className="hidden screensaver"></div>
        <div className="hidden black-overlay chicago">
          <p>i see you</p>
        </div>
        <Navigation></Navigation>
        <div style={FolderContainer}>
          <FolderIcon></FolderIcon>
        </div>
      </div>
    );
  }
}

export default App;
