import { combineReducers } from 'redux';
import {
  handleCloseWindow,
  handleShowFinalWindow,
  handleZIndexUpdate,
} from '../utils/helpers';

const ww = window.screen.width;
const hh = window.screen.height;

let data = {
  basicWidth: '450px',
  documentsWidth: '390px',
  uglyWidth: '250px',
  biggerWidth: '600px',
  collageWidth: '800px',
  postitWidth: '250px',
  postitWidthTwo: '250px',
  collageLeft: '20%',
  collageTop: '20%',
  postitTop: '15%',
  postitTwoTop: '17%',
  postitLeft: '80%',
  postitTwoLeft: '78%',
  top190: '190px',
  left190: '190px',
  top200: '200px',
  left200: '200px',
  top210: '210px',
  left210: '210px',
  showCollage: true,
  showPostit: false,
  showPostitTwo: true,
  radioLeft: '50px',
  redButtonLeft: '60px',
  manExpLeft: '55%',
  manExpTop: '45%',
  showManExp: true,
  elExpLeft: '15%',
  elExpTop: '15%',
  showElExp: true,
  emmaBuildLeft: '25%',
  emmaBuildTop: '35%',
  showEmmaBuild: true,
  flowerExpLeft: '10%',
  flowerExpTop: '37%',
  showFlowerExp: true,
  hippoExpLeft: '36%',
  hippoExpTop: '17%',
  showHippoExp: true,
  hippoExp2Left: '59%',
  hippoExp2Top: '32%',
  showHippoExp2: true,
  laharExpLeft: '29%',
  laharExpTop: '5%',
  showLaharExp: true,
  legzExpLeft: '62%',
  legzExpTop: '58%',
  showThcExp: true,
  thcExpLeft: '58%',
  thcExpTop: '6%',
  showLegzExp: true,
  showReelLeft: '55%',
  showReelTop: '45%',
  showShowreel: true,
  trGifLeft: '55%',
  trGifTop: '5%',
  showTrGif: true,
  kotaLeft: '70px',
  kotaTop: '270px',
  showKota: true,
  uglyLeft: '75%',
  uglyTop: '5%',
  showUgly: true,
};

if (ww < 900) {
  data.basicWidth = `450px`;
  data.showCollage = false;
  data.showPostit = false;
  data.showPostitTwo = false;
  data.showManExp = false;
  data.showUgly = false;
  data.uglyTop = '50px';
  data.uglyLeft = '50%';
  data.uglyWidth = '100px';
  data.showShowreel = false;
  data.top190 = '35px';
  data.postitLeft = '30%';
  data.postitTwoLeft = '31%';
  data.top200 = '45px';
  data.top210 = '55px';
  data.left190 = '10px';
  data.left200 = '20px';
  data.left210 = '30px';
  data.showManExp = false;
  data.showElExp = false;
  data.showEmmaBuild = false;
  data.showFlowerExp = false;
  data.showHippoExp = false;
  data.showHippoExp2 = false;
  data.showLaharExp = false;
  data.showLegzExp = false;
  data.showThcExp = false;
  data.showTrGif = false;
  
  if (ww > hh) {
    data.radioLeft = ww - 520 + 'px';
    data.redButtonLeft = ww - 210 + 'px';
  } else {
    data.radioLeft = hh - 320 + 'px';
    data.redButtonLeft = hh - 210 + 'px';
  }
}

const initialState = {
  windowsData: [
    {
      title: 'sad memes',
      show: false,
      z: 4000,
      width: data.basicWidth,
      position: {
        top: data.top190,
        left: data.left190,
      },
    },
    {
      title: 'Collage',
      show: false,
      z: 10,
      width: data.collageWidth,
      position: {
        top: data.collageTop,
        left: data.collageLeft,
      },
    },
    {
      title: 'Postit',
      show: data.showPostit,
      z: 11,
      width: data.postitWidth,
      position: {
        top: data.postitTop,
        left: data.postitLeft,
      },
    },
    {
      title: 'PostitTwo',
      show: data.showPostitTwo,
      z: 10,
      width: data.postitWidthTwo,
      position: {
        top: data.postitTwoTop,
        left: data.postitTwoLeft,
      },
    },
    // {
    //     title: 'Projects',
    //     show: false,
    //     z: 10,
    //     width: data.basicWidth,
    //     position: {
    //         top: data.top190,
    //         left: data.left190,
    //     },
    // },
    {
      title: 'Man Exp',
      show: data.showManExp,
      z: 100,
      width: data.basicWidth,
      position: {
        left: data.manExpLeft,
        top: data.manExpTop,
      },
    },
    {
      title: 'El Exp',
      show: data.showElExp,
      z: 100,
      width: data.documentsWidth,
      position: {
        left: data.elExpLeft,
        top: data.elExpTop,
      },
    },
    {
      title: 'Emma Build',
      show: data.showEmmaBuild,
      z: 100,
      width: data.basicWidth,
      position: {
        left: data.emmaBuildLeft,
        top: data.emmaBuildTop,
      },
    },
    {
      title: 'Flower Exp',
      show: data.showFlowerExp,
      z: 100,
      width: data.basicWidth,
      position: {
        left: data.flowerExpLeft,
        top: data.flowerExpTop,
      },
    },
    {
      title: 'Hippo Exp',
      show: data.showHippoExp,
      z: 100,
      width: data.basicWidth,
      position: {
        left: data.hippoExpLeft,
        top: data.hippoExpTop,
      },
    },
    {
      title: 'Hippo Exp 2',
      show: data.showHippoExp2,
      z: 100,
      width: data.basicWidth,
      position: {
        left: data.hippoExp2Left,
        top: data.hippoExp2Top,
      },
    },
    {
      title: 'Lahar Exp',
      show: data.showLaharExp,
      z: 100,
      width: data.basicWidth,
      position: {
        left: data.laharExpLeft,
        top: data.laharExpTop,
      },
    },
    {
      title: 'Legz Exp',
      show: data.showLegzExp,
      z: 100,
      width: data.basicWidth,
      position: {
        left: data.legzExpLeft,
        top: data.legzExpTop,
      },
    },
    {
        title: 'Thc Exp',
        show: data.showThcExp,
        z: 100,
        width: '320px',
        position: {
            left: data.thcExpLeft,
            top: data.thcExpTop,
        },
    },
    {
      title: 'Showreel',
      show: data.showShowreel,
      z: 200,
      width: data.biggerWidth,
      position: {
        left: data.showReelLeft,
        top: data.showReelTop,
      },
    },
    {
      title: 'Tr Gif',
      show: data.showTrGif,
      z: 310,
      width: data.documentsWidth,
      position: {
        left: data.trGifLeft,
        top: data.trGifTop,
      },
    },
    {
      title: 'Kota',
      show: data.showKota,
      z: 310,
      width: '60px',
      position: {
        left: data.kotaLeft,
        top: data.kotaTop,
      },
    },
    {
      title: 'Ugly',
      show: data.showUgly,
      z: 330,
      width: data.uglyWidth,
      position: {
        left: data.uglyLeft,
        top: data.uglyTop,
      },
    },
    {
      title: 'Bin',
      show: false,
      z: 10,
      width: data.basicWidth,
      position: {
        top: data.top190,
        left: data.left190,
      },
    },
    {
      title: 'Documents',
      show: false,
      width: data.documentsWidth,
      z: 10,
      position: {
        top: data.top190,
        left: data.left190,
      },
    },
    {
      title: 'Selfies',
      show: false,
      width: data.basicWidth,
      z: 10,
      position: {
        top: data.top190,
        left: data.left190,
      },
    },
    {
      title: 'Videos',
      show: false,
      width: data.basicWidth,
      z: 100,
      position: {
        top: data.top200,
        left: data.left200,
      },
    },
    {
      title: 'Photography',
      show: false,
      width: data.basicWidth,
      z: 100,
      position: {
        top: data.top200,
        left: data.left200,
      },
    },
    {
      title: 'Notebook',
      show: false,
      width: data.biggerWidth,
      z: 100,
      position: {
        top: data.top210,
        left: data.left210,
      },
    },
    {
      title: 'Legal',
      show: false,
      width: data.biggerWidth,
      z: 100,
      position: {
        top: data.top210,
        left: data.left210,
      },
    },
    {
      title: 'Todo',
      show: false,
      width: data.basicWidth,
      z: 100,
      position: {
        top: data.top210,
        left: data.left210,
      },
    },
    {
      title: 'Graphics',
      show: false,
      width: data.basicWidth,
      z: 100,
      position: {
        top: data.top200,
        left: data.left200,
      },
    },
    {
      title: 'Red Button',
      show: false,
      width: data.basicWidth,
      z: 100,
      position: {
        top: data.top200,
        left: '300px',
      },
    },
    {
      title: 'Animation',
      show: false,
      width: data.basicWidth,
      z: 100,
      position: {
        top: data.top200,
        left: data.left200,
      },
    },
    {
      title: 'Photographies',
      show: false,
      z: 1000,
      content: '',
    },
    {
      title: 'VideosPage',
      show: false,
      z: 1000,
      content: '',
    },
    {
      title: 'GraphicsPage',
      show: false,
      z: 1000,
      content: '',
    },
    {
      title: 'AnimationsPage',
      show: false,
      z: 1000,
      content: '',
    },
  ],
  iconsData: [
    // {
    //     title: 'Projects',
    //     tooltip: 'Projects',
    //     bg: 'red',
    //     icon: 'red-folder',
    //     top: '90px',
    // },
    {
      title: 'Documents',
      tooltip: 'Documents',
      bg: 'blue',
      icon: 'blue-folder',
      top: '90px',
    },
    {
      title: 'sad memes',
      tooltip: 'Woof woof',
      bg: 'pink',
      icon: 'red-folder',
      top: '180px',
    },
    {
      title: 'Radio',
      tooltip: 'Radio',
      bg: 'pink',
      icon: 'radio',
      bottom: '30px',
      left: data.radioLeft,
    },
    {
      title: 'Red Button',
      tooltip: 'Click me!',
      bg: 'pink',
      icon: 'red-button',
      bottom: '200px',
      left: data.redButtonLeft,
    },
    {
      title: 'Bin',
      tooltip: 'Trash',
      bg: 'pink',
      icon: 'bin',
      bottom: '30px',
      right: '50px',
    },
  ],
  eventListener: false,
};

const windowsReducer = (state = initialState, action) => {
  if (action.type === 'UPDATE_INDEX') {
    if (action.payload.title === 'Red Button') state.eventListener = true;
    let res = handleZIndexUpdate(state.windowsData, action.payload.title);
    return { ...state, windowsData: res };
  }

  if (action.type === 'CLOSE_WINDOW') {
    if (action.payload.title === 'Red Button') state.eventListener = false;
    else state.eventListener = true;
    let res = handleCloseWindow(state.windowsData, action.payload.title);
    return { ...state, windowsData: res };
  }

  if (action.type === 'SHOW_PHOTOGRAPHIES_WINDOW') {
    let res = handleShowFinalWindow(state.windowsData, action.payload, 'Photographies');
    return { ...state, windowsData: res };
  }

  if (action.type === 'SHOW_GRAPHICS_WINDOW') {
    state.eventListener = false;
    let res = handleShowFinalWindow(state.windowsData, action.payload, 'GraphicsPage');
    return { ...state, windowsData: res };
  }

  if (action.type === 'SHOW_ANIMATIONS_WINDOW') {
    state.eventListener = false;
    let res = handleShowFinalWindow(state.windowsData, action.payload, 'AnimationsPage');
    return { ...state, windowsData: res };
  }

  if (action.type === 'SHOW_VIDEOS_WINDOW') {
    state.eventListener = false;
    let res = handleShowFinalWindow(state.windowsData, action.payload, 'VideosPage');
    return { ...state, windowsData: res };
  }

  return initialState;
};

const selectedWindowReducer = (selectedWindow = null, action) => {
  if (action.type === 'UPDATE_INDEX') {
    return action.payload;
  }

  return selectedWindow;
};

export default combineReducers({
  windows: windowsReducer,
  selectedWindow: selectedWindowReducer,
});
