import React from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { closeWindow, showGraphics, updateIndex } from '../actions';
import newProject from '../images/desktop/data/graphics/website/lahar.gif';
import lines from '../images/desktop/windows/lines.png';
import minim from '../images/desktop/windows/minim.png';
import close from '../images/desktop/windows/x.png';
import { getWindowsProps } from '../utils/helpers';

class LaharExp extends React.Component {
  render() {
    const elExpData = getWindowsProps(
      this.props.windows.windowsData,
      'Lahar Exp'
    );
    // const videosData = getWindowsProps(this.props.windows.windowsData, 'Videos');
    // const photographyData = getWindowsProps(this.props.windows.windowsData, 'Photography');
    // const graphicsData = getWindowsProps(this.props.windows.windowsData, 'Graphics');
    // const animationData = getWindowsProps(this.props.windows.windowsData, 'Animation');

    const windowStyle = {
      position: 'absolute',
      top: elExpData.position.top,
      left: elExpData.position.left,
      zIndex: elExpData.z
    };

    const linesBg = {
      backgroundColor: '#F0EDED',
      backgroundImage: `url(${lines})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '85% 45%'
    };

    const windowsWidth = {
      width: elExpData.width
    };

    return (
      <Draggable
        bounds="body"
        onMouseDown={() => this.props.updateIndex(elExpData)}
      >
        <div
          style={windowStyle}
          className={elExpData.show ? '' : 'hidden'}
        >
          <div className="mother work600" style={windowsWidth}>
            <div style={linesBg} className="topbar">
              <div className="child close">
                <img
                  src={close}
                  onClick={() =>
                    this.props.closeWindow({ ...elExpData, show: false })
                  }
                  alt=""
                />
              </div>
              <div className="child title">
                <span>lahar berlin limbo</span>
              </div>
              <div className="child maximise">
                <img src={minim} alt="" />
              </div>
            </div>
            <div className="bar">
              <div className="child items">
                <p>1 item</p>
              </div>
              <div className="child disk">
                <p>2.1 GB in disk</p>
              </div>
              <div className="child available">
                <p>22 MB available</p>
              </div>
            </div>
            <div className="np-container emma-po work600">
              <div className="project">
                <div className="content">
                  <img alt="" 
                    src={newProject}
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
    updateIndex,
    showGraphics,
    closeWindow,
})(LaharExp)
