import React from 'react'
import { connect } from 'react-redux'
import Typed from 'react-typed'
import {
    closeWindow,
    showAnimations,
    showGraphics,
    showVideosWindow,
    updateIndex
} from '../actions'
import project3 from '../images/desktop/data/videos/hippocampus/thumb.jpg'
import { getWindowsProps } from '../utils/helpers'

class Carousel extends React.Component {
    doneTyping = () => {
        const sc = document.getElementsByClassName('scroll-container')[0]
        sc.classList.remove('hidden-scroll')
    }

    nextDotPlease() {
        const i = this.index
        const current =
            document.getElementsByClassName('dots-container')[0].childNodes[i]
        const next =
            document.getElementsByClassName('dots-container')[0].childNodes[
                i + 1
            ]
        current.classList.remove('active-dot')
        next.classList.add('active-dot')
    }

    previousDotPlease() {
        const i = this.index
        const current =
            document.getElementsByClassName('dots-container')[0].childNodes[i]
        const next =
            document.getElementsByClassName('dots-container')[0].childNodes[
                i - 1
            ]
        current.classList.remove('active-dot')
        next.classList.add('active-dot')
    }

    scrollMeDown = () => {
        if (this.index > 0) return

        const content = document.querySelectorAll('.project')
        this.nextDotPlease()
        this.index++
        const next = content[this.index]
        next.classList.remove('hidden-down')
        const nextChildren = next.childNodes[0].childNodes
        nextChildren.forEach((section, i) => {
            section.classList.remove('hide-a-bit')
        })
    }

    scrollMeUp = () => {
        if (this.index < 1) return

        const content = document.querySelectorAll('.project')
        this.previousDotPlease()
        const prev = content[this.index]
        this.index--
        prev.classList.add('hidden-down')
        const prevChildren = prev.childNodes[0].childNodes
        setTimeout(() => {
            prevChildren.forEach((section, i) => {
                section.classList.add('hide-a-bit')
            })
        }, 500)
    }

    figureOutScroll = (event) => {
        var delta = event.wheelDelta
        var timeNow = new Date().getTime()
        // Cancel scroll if currently animating or within quiet period
        if (
            timeNow - this.lastAnimation <
            this.idlePeriod + this.animationDuration
        ) {
            return
        }

        if (delta < 0) {
            this.scrollMeDown()
        } else {
            this.scrollMeUp()
        }

        this.lastAnimation = timeNow
    }

    figureOutTouchScroll = (event) => {
        const currentY = event.changedTouches[0].clientY

        var timeNow = new Date().getTime()
        // Cancel scroll if currently animating or within quiet period
        if (
            timeNow - this.lastAnimation <
            this.idlePeriod + this.animationDuration
        ) {
            return
        }

        if (currentY < this.initialTouch) {
            this.scrollMeDown()
        } else if (currentY > this.initialTouch) {
            this.scrollMeUp()
        }

        this.lastAnimation = timeNow
    }

    getStart = (event) => {
        this.initialTouch = event.touches[0].clientY
    }

    showProject(title) {
        switch (title) {
            case 'showreel':
                this.props.showVideosWindow('showreel')
                break
            case 'brandarmies':
                this.props.showGraphics('brandarmies')
                break
            case 'hippocampus':
                this.props.showVideosWindow('hippocampus')
                break
            case 'laharLimbo':
                this.props.showGraphics('laharLimbo')
                break
            default:
            // code block
        }
        document.removeEventListener('wheel', this.figureOutScroll)
        document.removeEventListener('touchstart', this.getStart)
        document.removeEventListener('touchend', this.figureOutTouchScroll)
    }
    componentDidMount() {
        this.content = document.querySelectorAll('.project')

        this.idlePeriod = 500
        this.animationDuration = 500

        this.lastAnimation = 0
        this.index = 0

        this.content.forEach((section, i) => {
            section.style.zIndex = i
        })

        this.index = 0
    }

    render() {
        const redData = getWindowsProps(
            this.props.windows.windowsData,
            'Red Button'
        )

        this.initialTouch = 0
        if (this.props.windows.eventListener) {
            document.addEventListener('wheel', this.figureOutScroll)
            document.addEventListener('touchstart', this.getStart)
            document.addEventListener('touchend', this.figureOutTouchScroll)
        } else {
            document.removeEventListener('wheel', this.figureOutScroll)
            document.removeEventListener('touchstart', this.getStart)
            document.removeEventListener('touchend', this.figureOutTouchScroll)
        }

        if (redData.show) {
            setTimeout(() => {
                this.typed.start()
            }, 1000)
        } else if (this.typed && redData.show) {
            this.typed.toggle()
        }

        const color0 = {
            backgroundColor: 'black',
        }

        const color1 = {
            backgroundColor: '#0a0a0a',
        }

        const color2 = {
            backgroundColor: 'white',
        }

        const color3 = {
            backgroundColor: 'black',
        }

        const black = {
            color: 'black',
        }

        return (
            <div className='my-projects-container'>
                <div className='dots-container'>
                    <div className='dot active-dot'></div>
                    <div className='dot'></div>
                </div>
                <div className='project'>
                    <div className='body work400' style={color0}>
                        <Typed
                            strings={[
                                'Good mornfsing',
                                'Good morning',
                                'Hello',
                                'Hi there, <br>more projects coming soon.',
                                'Hi there, <br>more projects coming soon.<br>Thank you for visiting!',
                                'Hi there, <br>more projects coming soon.<br>Please come back!',
                                'Hi there, <br>more projects coming soon.<br>Hope you enjoy!<br>¯_(ツ)_/¯',
                            ]}
                            typedRef={(typedInstance) => {
                                this.typed = typedInstance
                            }}
                            typeSpeed={60}
                            backSpeed={30}
                            onComplete={this.doneTyping}
                            backDelay={10}
                            stopped
                        />
                        <div
                            className='scroll-container hidden-scroll'
                            onClick={this.scrollMeDown}>
                            <p>THIS WAY PLEASE</p>
                        </div>
                    </div>
                </div>
               
                <div className='project hidden-down'>
                    <div className='body work400' style={color3}>
                        <div
                            className='preview hide-a-bit'
                            onClick={() => this.showProject('hippocampus')}>
                            <img alt='' src={project3}></img>
                        </div>
                        <div className='text hide-a-bit'>
                            <p>Hippocampus</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {
    updateIndex,
    closeWindow,
    showGraphics,
    showVideosWindow,
    showAnimations,
})(Carousel)
